<template>
  <div class="my-4">
    <p v-html="mainText" class="m-0"></p>
    <button
      class="d-block mt-4 me-4 arbor-btn btn btn-warning"
      style="min-width: 195px"
      @click="confirmAction"
    >
      {{ confirmActionText }}
    </button>
    <button
      class="d-block mt-3 btn btn-outline-success"
      style="min-width: 195px"
      @click="cancelAction"
    >
      {{ cancelActionText }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ConfirmActionDialog',
  emits: ['dialogCompleted'],
  props: {
    mainText: { type: String, required: true },
    confirmActionText: { type: String, required: true },
    cancelActionText: { type: String, required: true },
  },
  data() {
    return {};
  },
  methods: {
    confirmAction() {
      this.$emit('dialogCompleted', true);
    },
    cancelAction() {
      this.$emit('dialogCompleted', false);
    },
  },
};
</script>
