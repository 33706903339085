<template>
  <nav class="navbar navbar-light arbor-navbar">
    <div class="container-fluid">
      <router-link
        :to="{
          name: 'RootProductList',
        }"
        class="navbar-brand"
      >
        <img src="/arbor.png" alt="Arbor logo" width="91" height="41" />
      </router-link>
      <button
        class="navbar-toggler arbor-navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse no-transition" id="navbarNav">
        <ul class="navbar-nav">
          <template v-if="$store.state.user">
            <template v-if="$store.state.user.currentWorkspace">
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'RootProductList',
                  }"
                  class="nav-link"
                >
                  Produtos raiz
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'CreateProductStepOne',
                  }"
                  class="nav-link"
                >
                  Instalar produto
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'Sync',
                  }"
                  class="nav-link"
                >
                  Sincronizar
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'RemoteTechnicalSupport',
                  }"
                  class="nav-link"
                >
                  Ferramentas de suporte remoto
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'Sync',
                    query: { signingOut: 'true' },
                  }"
                  class="nav-link"
                >
                  Sair do aplicativo
                </router-link>
              </li>
              <li class="nav-item d-inline-block mt-4">
                <b>Autenticado como: </b>{{ $store.state.user.fullName }}<br />
                <b>Área de trabalho: </b
                >{{
                  i18n.t(['workspaces', $store.state.user.currentWorkspace])
                }}
              </li>
            </template>
            <template v-else>
              <li class="nav-item d-inline-block mt-4">
                <b>Autenticado como: </b>{{ $store.state.user.fullName }}<br />
                <b>Área de trabalho: </b> (Ainda não selecionada)
              </li>
            </template>
          </template>
          <template v-else>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'SignIn',
                }"
                class="nav-link"
              >
                Entrar no aplicativo
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import store from '../store/store';
import authorization from '../auth/authorization';
import i18n from '../i18n/i18n';

export default {
  name: 'Navbar',
  setup() {
    return { i18n };
  },
  data() {
    return {};
  },
  computed: {
    isAdmin() {
      return authorization.isAdmin(store.state.user);
    },
    isTec() {
      return authorization.isTec(store.state.user);
    },
  },
};
</script>
