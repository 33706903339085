<template>
  <p class="fw-bold" :data-attachment-collection-id="attachmentCollection._id">
    {{ title }}
  </p>
  <div v-if="ready">
    <div>
      <div
        v-for="(file, i) in landscapeImgFiles"
        :key="`${i}-${file.digest}`"
        class="d-inline-block mb-3 me-3"
        style="max-width: 350px"
      >
        <img
          :src="`data:${file.content_type};base64,${file.data}`"
          style="break-inside: avoid"
          class="img-fluid rounded"
        />
      </div>
    </div>
    <div>
      <div
        v-for="(file, i) in portraitImgFiles"
        :key="`${i}-${file.digest}`"
        style="max-width: 228px; break-inside: avoid"
        class="d-inline-block mb-3 me-3"
      >
        <img
          :src="`data:${file.content_type};base64,${file.data}`"
          class="img-fluid rounded"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="spinner-border spinner-border-sm text-dark"></div>
    <p class="ms-2 d-inline-block">
      Baixando imagens desse evento... Por favor, aguarde, isso pode demorar
      alguns minutos...
    </p>
  </div>
</template>

<script>
import { RemoteDb, buildRemoteDbAddrForWorkspace } from '../db/db';
import { WorkReportDao } from '../dao/work_report_dao';

export default {
  name: 'WorkReportAttachmentCollection',
  props: {
    title: {
      type: String,
      required: true,
    },
    attachmentCollection: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    workspace: {
      type: String,
      required: true,
    },
  },
  created() {
    const remoteWorkspaceDb = new RemoteDb(
      buildRemoteDbAddrForWorkspace('workspace', this.workspace),
      {
        username: this.token,
        password: this.token,
      }
    );
    const remoteWorkspaceAttachmentsDb = new RemoteDb(
      buildRemoteDbAddrForWorkspace('workspaceAttachments', this.workspace),
      {
        username: this.token,
        password: this.token,
      }
    );
    const workReportDao = new WorkReportDao(
      remoteWorkspaceDb,
      remoteWorkspaceAttachmentsDb
    );

    this.downloadAttachments(workReportDao, true);
  },
  data() {
    return {
      imagesLoaded: false,
      attachments: {},
      landscapeImgFiles: [],
      portraitImgFiles: [],
    };
  },
  computed: {
    files() {
      return Object.values(this.attachments);
    },
    imagesProcessed() {
      return (
        this.files.length > 0 &&
        this.files.length ===
          this.landscapeImgFiles.length + this.portraitImgFiles.length
      );
    },
    ready() {
      return this.imagesLoaded && this.imagesProcessed;
    },
  },
  methods: {
    downloadAttachments(workReportDao, fetchOptimizedIfAvailable) {
      const attachmentCollectionId = fetchOptimizedIfAvailable
        ? `${this.attachmentCollection._id}::small`
        : this.attachmentCollection._id;

      workReportDao.attachmentFiles(
        [attachmentCollectionId],
        (attachmentCollectionWithAttachmentsArr) => {
          if (
            attachmentCollectionWithAttachmentsArr.length === 0 &&
            fetchOptimizedIfAvailable
          ) {
            // optimized images are not available; we try once more, but this time we attempt to fetch the original images.
            this.downloadAttachments(workReportDao, false);
            return;
          }

          const attachmentCollectionWithAttachments =
            attachmentCollectionWithAttachmentsArr[0];
          this.attachments = attachmentCollectionWithAttachments._attachments;
          this.imagesLoaded = true;
          this.groupImagesByOrientation();
        }
      );
    },
    groupImagesByOrientation() {
      this.files.forEach((file) => {
        const img = new Image();
        img.onload = () => {
          img.width >= img.height
            ? this.landscapeImgFiles.push(file)
            : this.portraitImgFiles.push(file);
        };
        img.src = `data:${file.content_type};base64,${file.data}`;
      });
    },
  },
};
</script>
