<template>
  <file-pond
    name="camera"
    ref="pond"
    accepted-file-types="image/png, image/jpeg, image/gif"
    credits="false"
    :label-idle="labelIdle"
    allow-multiple="true"
    allow-drop="false"
    allow-browse="true"
    :capture-method="null"
    required="false"
    :files="files"
    @init="handleFilePondInit"
  />
</template>

<script>
import i18n from '../i18n/i18n';

import vueFilePond from 'vue-filepond';
import ptBr from 'filepond/locale/pt-br';
import 'filepond/dist/filepond.min.css';
import '../assets/third_party/filepond_overrides.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: 'Camera',
  components: {
    FilePond,
  },
  props: {
    idleText: String,
  },
  data() {
    return {
      i18n: i18n,
      files: null,
    };
  },
  methods: {
    handleFilePondInit() {},
    anyFiles() {
      return this.$refs.pond.getFiles().length > 0;
    },
    getFileBlobs() {
      return this.$refs.pond.getFiles().map((pondFile) => pondFile.file);
    },
  },
  computed: {
    labelIdle() {
      if (this.idleText)
        return `<span class="filepond--label-action">${this.idleText}</span>`;
      else return this.i18n.translate('thirdParty.filepond.labelIdle');
    },
  },
};
</script>
