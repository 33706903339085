export default {
  methods: {
    coalesceSyncStatuses(...statuses) {
      if (statuses.includes('working')) return 'working';
      else if (statuses.includes('waiting')) return 'waiting';
      else if (statuses.includes('error')) return 'error';
      else return 'complete';
    },
    syncStatusClasses(status) {
      if (status === 'error') return 'text-danger';
      else if (status === 'complete') return 'text-success';
      else return '';
    },
  },
};
