<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Iniciar substituição de produto</h1>
        <h3>{{ product.data.name }}</h3>
        <router-link
          v-show="!showConfirmActionDialog"
          :to="{
            name: 'ChildProductList',
            params: { productId: product.parentId },
          }"
          class="mt-2 btn btn-outline-dark"
        >
          <i class="bi-arrow-up"></i>
          Voltar
        </router-link>
        <ConfirmActionDialog
          v-show="showConfirmActionDialog"
          @dialogCompleted="handleDialogCompleted"
          :mainText="i18n.translate('views.productReplacement.noPhotosWarning')"
          :confirmActionText="i18n.translate('actions.confirmSave')"
          :cancelActionText="
            i18n.translate('views.productReplacement.backAddPhotos')
          "
        />
        <form
          v-show="!showConfirmActionDialog"
          class="my-4"
          @submit.prevent="submitAction"
          novalidate
        >
          <Camera ref="camera" idleText="Tirar fotos (antes da substituição)" />
          <button class="my-4 btn btn-success">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmActionDialog from '../components/ConfirmActionDialog';
import Camera from '../components/Camera';
import productDao from '../dao/product_dao';
import productReplacementDao from '../dao/product_replacement_dao';
import attachmentDao from '../dao/attachment_dao';
import i18n from '../i18n/i18n';
import store from '../store/store';
import ProductHelper from '../helpers/product_helper';
import { useToast } from 'vue-toastification';

export default {
  name: 'ReplaceProductStepOne',
  components: {
    ConfirmActionDialog,
    Camera,
  },
  props: {
    productId: String,
  },
  setup() {
    return { i18n, toast: useToast() };
  },
  beforeCreate() {
    store.commit('activateFullscreenOverlay');
  },
  created() {
    productDao.product(this.productId, (product) => {
      this.product = product;
      productReplacementDao.pendingProductReplacement(
        this.productId,
        (productReplacement) => {
          store.commit('deactivateFullscreenOverlay');

          if (productReplacement) {
            if (productReplacement.status === 'started') {
              this.$router.push({
                name: 'ProductReplacementStepTwo',
                params: { productReplacementId: productReplacement._id },
              });
            } else if (productReplacement.status === 'picturesTaken') {
              this.$router.push({
                name: 'CreateProductStepTwo',
                params: { productType: new ProductHelper(this.product).type() },
                query: {
                  replacedProductId: this.product._id,
                  productReplacementId: productReplacement._id,
                  prefillInstalledAt: true,
                },
              });
            } else {
              this.$router.push({
                name: 'RootProductList',
              });
              this.toast.success(
                i18n.translate('views.productReplacement.alreadyReplaced')
              );
            }
          }
        }
      );
    });
  },
  data() {
    return {
      showConfirmActionDialog: false,
      product: {
        data: {
          name: '',
        },
      },
    };
  },
  methods: {
    submitAction() {
      if (this.$refs.camera.anyFiles()) {
        this.saveProductReplacement();
      } else {
        this.showConfirmActionDialog = true;
        return;
      }
    },
    handleDialogCompleted(confirmed) {
      if (confirmed) this.saveProductReplacement();
      else this.showConfirmActionDialog = false;
    },
    saveProductReplacement() {
      store.commit('activateFullscreenOverlay');

      const productReplacement = {
        replacedProductId: this.product._id,
      };
      productReplacementDao.startProductReplacement(
        productReplacement,
        (productReplacementId) => {
          if (this.$refs.camera.anyFiles()) {
            const metadata = {
              ownerDocId: productReplacementId,
              labels: ['beforeProductReplacement'],
            };
            attachmentDao.saveAttachments(
              metadata,
              this.$refs.camera.getFileBlobs(),
              this.afterSaveProductReplacement
            );
          } else {
            this.afterSaveProductReplacement();
          }
        }
      );
    },
    afterSaveProductReplacement() {
      store.commit('deactivateFullscreenOverlay');
      this.toast.success(i18n.translate('actions.saved'));
      this.$router.push({
        name: 'ShowProduct',
        params: { productId: this.product._id },
      });
    },
  },
};
</script>
