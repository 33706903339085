<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Ferramentas de suporte remoto</h1>
        <a
          class="mt-3 btn btn-outline-dark"
          :class="uploadBtnClasses"
          href="#"
          @click="uploadLocalDatabases"
        >
          <span v-show="!isUploading">Enviar dados locais para o suporte</span>
          <div v-show="isUploading">
            <span class="spinner-border spinner-border-sm"></span>
            Enviando...
          </div>
        </a>
        <p class="mt-3">{{ msgArborDb }}</p>
        <p class="mt-3">{{ msgWorkspaceDb }}</p>
        <p class="mt-3">{{ msgWorkspaceAttachmentsDb }}</p>
        <p class="mt-3">{{ lastError }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  arborDb,
  workspaceDb,
  workspaceAttachmentsDb,
  buildRemoteDebugDbAddr,
  RemoteDb,
} from '../db/db';

export default {
  name: 'RemoteTechnicalSupport',
  data() {
    return {
      now: null,
      stateArborDb: 'waiting',
      stateWorkspaceDb: 'waiting',
      stateWorkspaceAttachmentsDb: 'waiting',
      lastError: '',
    };
  },
  methods: {
    async uploadWorkspaceDb(ctx) {
      let self = this;

      let localDb = ctx === 'arbor' ? arborDb : workspaceDb;
      let uiState = ctx === 'arbor' ? 'stateArborDb' : 'stateWorkspaceDb';

      try {
        self[uiState] = 'uploading';

        let res = await localDb.db().allDocs({ include_docs: true });
        const dump = {
          _id: `db_dump::${ctx}::${this.now}`,
          data: res.rows,
        };

        let addr = await buildRemoteDebugDbAddr(ctx);
        const remoteDebugDb = new RemoteDb(addr);
        await remoteDebugDb.db().put(dump);

        console.log(`${ctx} dump uploaded.`);
        self[uiState] = 'done';
      } catch (err) {
        console.log(err);
        this.lastError = err;
        self[uiState] = 'error';
      }
    },
    async uploadWorkspaceAttachmentsDb() {
      try {
        this.stateWorkspaceAttachmentsDb = 'uploading';

        let res = await workspaceAttachmentsDb
          .db()
          .allDocs({ include_docs: false, attachments: false });
        let addr = await buildRemoteDebugDbAddr('workspaceAttachments');

        const remoteDebugDb = new RemoteDb(addr);

        for (let attachmentDoc of res.rows) {
          let completeDoc = await workspaceAttachmentsDb
            .db()
            .get(attachmentDoc.id, { attachments: true });

          delete completeDoc._rev;
          completeDoc._id = `${completeDoc._id}::${this.now}`;
          await remoteDebugDb.db().put(completeDoc);

          console.log(`attachment ${completeDoc._id} uploaded.`);
        }

        console.log('workspaceAttachmentsDb dump uploaded');
        this.stateWorkspaceAttachmentsDb = 'done';
      } catch (err) {
        console.log(err);
        this.stateWorkspaceAttachmentsDb = 'error';
      }
    },
    uploadLocalDatabases() {
      this.now = Date.now();

      this.uploadWorkspaceDb('arbor');
      this.uploadWorkspaceDb('workspace');
      this.uploadWorkspaceAttachmentsDb();
    },
  },
  computed: {
    isUploading() {
      return [
        this.stateArborDb,
        this.stateWorkspaceDb,
        this.stateWorkspaceAttachmentsDb,
      ].includes('uploading');
    },
    msgArborDb() {
      switch (this.stateArborDb) {
        case 'waiting':
          return '';
        case 'uploading':
          return 'Enviando dados compartilhados... ';
        case 'done':
          return 'Enviando dados compartilhados... Pronto!';
        default:
          // error
          return 'Enviando dados compartilhados... Falhou.';
      }
    },
    msgWorkspaceDb() {
      switch (this.stateWorkspaceDb) {
        case 'waiting':
          return '';
        case 'uploading':
          return 'Enviando dados de manutenção coletados... ';
        case 'done':
          return 'Enviando dados de manutenção coletados... Pronto!';
        default:
          // error
          return 'Enviando dados de manutenção coletados... Falhou.';
      }
    },
    msgWorkspaceAttachmentsDb() {
      switch (this.stateWorkspaceAttachmentsDb) {
        case 'waiting':
          return '';
        case 'uploading':
          return 'Enviando fotos... ';
        case 'done':
          return 'Enviando fotos... Pronto!';
        default:
          // error
          return 'Enviando fotos... Falhou.';
      }
    },
    uploadBtnClasses() {
      return this.isUploading ? 'disabled' : '';
    },
  },
};
</script>
