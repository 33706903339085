import i18n from '../i18n/i18n';
import { DateTime } from 'luxon';

const appViewHelper = {
  format(value) {
    if (value === null || value === undefined) return '-';

    return value;
  },
  formatDateTime(iso8601, preset = 'DATETIME_SHORT') {
    const dt = DateTime.fromISO(iso8601);
    return dt.setLocale('pt-BR').toLocaleString(DateTime[preset]);
  },
  printRecordDefaultFields(obj) {
    const fields = [
      'createdAt',
      'createdByFullName',
      'updatedAt',
      'updatedByFullName',
    ];
    let labelsAndValues = [];
    const dateTimeFields = ['createdAt', 'updatedAt'];

    for (const field of fields) {
      const translatedLabel = i18n.translate(`models.shared.${field}`);
      if (dateTimeFields.includes(field))
        labelsAndValues.push([
          translatedLabel,
          this.formatDateTime(obj[field]),
        ]);
      else labelsAndValues.push([translatedLabel, this.format(obj[field])]);
    }

    return labelsAndValues;
  },
  productType(schemaId) {
    return i18n.translate(
      `collections.productTypes.${schemaId.split('::')[2]}`
    );
  },
};

export default appViewHelper;
