<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-8">
        <div v-if="propsValid">
          <div class="mt-3">
            <!-- <img
              style="max-width: 225px; height: auto"
              class="me-5"
              src="/logo-nexa.png"
              alt="Nexa logo"
            /> -->
            <img
              style="max-width: 225px; height: auto"
              src="/logo-mineratec.png"
              alt="Mineratec logo"
            />
          </div>
          <h5 class="mt-5">
            {{ reportTitle }}
          </h5>
          <h2 class="mt-3">{{ workspaceConfig.workReportName }}</h2>
          <div v-if="workReportStatus === 'loading'">
            <div class="spinner-border spinner-border-sm text-dark"></div>
            <p class="ms-2 d-inline-block">
              Gerando relatório... Por favor, aguarde, essa operação pode
              demorar até 1 minuto...
            </p>
          </div>
          <div v-if="workReportStatus === 'done'" class="mt-5">
            <div
              v-for="rootProduct in workReport.sortedRootProducts()"
              :key="rootProduct._id"
              class="mb-4"
            >
              <h3 class="text-uppercase fs-2 mb-5">
                {{ rootProduct.data.name }}
              </h3>
              <div
                v-for="product in rootProduct.products"
                :key="product._id"
                class="mb-4"
              >
                <h4>{{ product.data.name }}</h4>
                <h5 class="text-uppercase fs-6">
                  <span class="bi bi-tag-fill"></span>
                  {{ rootProduct.data.name }}
                </h5>
                <div
                  v-for="completedWork in product.completedWork"
                  :key="completedWork._id"
                  :data-completed-work-id="completedWork._id"
                  class="card arbor-card-light mb-4 px-3 py-2"
                  style="break-inside: avoid"
                >
                  <component
                    :is="determineCompletedWorkComponent(completedWork)"
                    :token="token"
                    :workspace="workspace"
                    :work="completedWork"
                    :isEditable="isEditable"
                  ></component>
                </div>
              </div>
              <hr class="text-muted" />
            </div>
          </div>
        </div>
        <div class="mt-3" v-else>
          Esse link parece ser inválido. Se você já gerou um relatório usando
          esse mesmo link, isso pode significar que ele foi desativado.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { RemoteDb, buildRemoteDbAddrForWorkspace } from '../db/db';
import { WorkReportDao } from '../dao/work_report_dao';
import { WorkReport } from '../models/work_report';
import appHelper from '../helpers/app_helper';
import appViewHelper from '../view_helpers/app_view_helper';
import WorkReportInspection from '../components/WorkReportInspection';
import WorkReportWorkExecution from '../components/WorkReportWorkExecution';
import WorkReportProductReplacement from '../components/WorkReportProductReplacement';
import WorkReportProductInstallation from '../components/WorkReportProductInstallation';
import store from '../store/store';
import authorization from '../auth/authorization';

export default {
  name: 'WorkReport',
  components: {
    WorkReportInspection,
    WorkReportWorkExecution,
    WorkReportProductReplacement,
    WorkReportProductInstallation,
  },
  props: {
    token: String,
    workspace: String,
    startDate: {
      type: String,
      default: function () {
        return DateTime.now().startOf('month').toISODate();
      },
    },
    endDate: {
      type: String,
      default: function () {
        return DateTime.now().endOf('month').toISODate();
      },
    },
  },
  setup() {
    return {
      appViewHelper: appViewHelper,
    };
  },
  created() {
    document.body.style.backgroundColor = '#edf0f2';

    this.startDateObj = DateTime.fromISO(this.startDate).startOf('day');
    this.endDateObj = DateTime.fromISO(this.endDate).endOf('day');
    this.remoteWorkspaceDb = new RemoteDb(
      buildRemoteDbAddrForWorkspace('workspace', this.workspace),
      {
        username: this.token,
        password: this.token,
      }
    );
    this.remoteWorkspaceAttachmentsDb = new RemoteDb(
      buildRemoteDbAddrForWorkspace('workspaceAttachments', this.workspace),
      {
        username: this.token,
        password: this.token,
      }
    );
    this.workReportDao = new WorkReportDao(
      this.remoteWorkspaceDb,
      this.remoteWorkspaceAttachmentsDb
    );

    this.workReportDao.getObjById('workspace_config', (config) => {
      this.workspaceConfig = config;
    });
    this.workReport = new WorkReport(
      this.workReportDao,
      () => (this.workReportStatus = 'done')
    );
    this.workReport.buildReport(
      DateTime.fromISO(this.startDate).startOf('day').toString(),
      DateTime.fromISO(this.endDate).endOf('day').toString()
    );
  },
  data() {
    return {
      remoteWorkspaceDb: null,
      remoteWorkspaceAttachmentsDb: null,
      workspaceConfig: { workReportName: '-' },
      workReport: null,
      workReportDao: null,
      startDateObj: null,
      endDateObj: null,
      workReportStatus: 'loading',
    };
  },
  computed: {
    propsValid() {
      return this.token && this.workspace ? true : false;
    },
    reportTitle() {
      if (
        !this.propsValid ||
        this.startDateObj === null ||
        this.endDateObj === null
      ) {
        return 'Relatório de atividades realizadas';
      }

      return `Relatório de atividades realizadas de ${appViewHelper.formatDateTime(
        this.startDateObj.toISO(),
        'DATE_SHORT'
      )} até ${appViewHelper.formatDateTime(
        this.endDateObj.toISO(),
        'DATE_SHORT'
      )}`;
    },
    isEditable() {
      return (
        authorization.isAdmin(store.state.user) &&
        authorization.currentWorkspaceMatches(store.state.user, this.workspace)
      );
    },
  },
  methods: {
    determineCompletedWorkComponent(work) {
      switch (appHelper.objTypeFromId(work._id)) {
        case 'inspection':
          return 'WorkReportInspection';
        case 'product_replacement':
          return 'WorkReportProductReplacement';
        case 'products':
          return 'WorkReportProductInstallation';
        default:
          return 'WorkReportWorkExecution';
      }
    },
  },
};
</script>
