import i18n from '../i18n/i18n';
import dao from '../dao/arbor_dao';

const formGen = {
  enhanceSchema(objSchema, done) {
    this.translateSchema(objSchema);
    Promise.all(this.loadArborCollections(objSchema)).then((_values) => done());
  },
  loadArborCollections(objSchema) {
    const collectionLoadingPromises = [];
    const properties = objSchema.properties;
    for (const propName in properties) {
      const arborCollectionId = properties[propName]['arborCollectionId'];
      if (arborCollectionId)
        collectionLoadingPromises.push(
          dao
            .arborCollection(arborCollectionId)
            .then((collection) =>
              properties[propName].enum.push(...collection.values)
            )
            .catch((err) => console.log(err))
        );
    }

    return collectionLoadingPromises;
  },
  translateSchema(objSchema) {
    const properties = objSchema.properties;
    for (const propName in properties) {
      properties[propName].title = i18n.translate(
        properties[propName].title.split('.')
      );
    }

    const propErrors = objSchema.errorMessage.properties;
    for (const propName in propErrors) {
      propErrors[propName] = i18n.translate(propErrors[propName].split('.'));
    }

    return objSchema;
  },
  genBlankSchema(objSchema) {
    const blankSchema = {};
    const properties = objSchema.properties;
    for (const propName in properties) {
      blankSchema[propName] = null;
    }

    return blankSchema;
  },
  genUiSchema(objSchema) {
    const uiSchema = {
      type: 'VerticalLayout',
      elements: [],
    };

    const properties = objSchema.properties;
    for (const propName in properties) {
      uiSchema.elements.push({
        type: 'Control',
        scope: `#/properties/${propName}`,
      });
    }

    return uiSchema;
  },
  arborStyles: {
    control: {
      root: 'mt-4',
      label: 'form-label',
      input: 'form-control',
      select: 'form-select',
      error: 'text-danger',
    },
  },
};

export default formGen;
