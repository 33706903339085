import store from '../store/store';

class Authorization {
  constructor() {
    this.adminViews = [];
  }

  isAdmin(user) {
    if (user === null) return false;

    return user.currentRoles.includes('admin');
  }

  isTec(user) {
    if (user === null) return false;

    return user.currentRoles.includes('tec');
  }

  isAuthorized(user, viewName) {
    if (this.adminViews.includes(viewName)) return this.isAdmin(user);

    return true;
  }

  currentWorkspaceMatches(user, expectedWorkspace) {
    if (user === null) return false;

    return user.currentWorkspace === expectedWorkspace;
  }
}

export default new Authorization();
