const dataTypes = {
  clean(value) {
    if (typeof value === 'string') value = value.trim();

    return value;
  },
  coerce(value) {
    if (value === '') value = null;

    return value;
  },
  coerceRecursively(obj, opts = {}) {
    const options = {
      cleanData: true,
    };
    Object.assign(options, opts);

    for (const key in obj) {
      if (this.isObject(obj[key])) {
        this.coerceRecursively(obj[key], options);
      } else {
        if (options.cleanData) obj[key] = this.clean(obj[key]);
        obj[key] = this.coerce(obj[key]);
      }
    }
  },
  isObject(obj) {
    // More info on why this check works: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object
    return obj === Object(obj);
  },
  pushToMaybeArray(maybeArray, val) {
    if (maybeArray === undefined || maybeArray === null) {
      return [val];
    } else {
      maybeArray.push(val);
      return maybeArray;
    }
  },
};

export default dataTypes;
