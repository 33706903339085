<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Iniciar registro de execução de serviço</h1>
        <h3>{{ product.data.name }}</h3>
        <router-link
          v-show="!showConfirmActionDialog"
          :to="{
            name: 'ChildProductList',
            params: { productId: product.parentId },
          }"
          class="mt-2 btn btn-outline-dark"
        >
          <i class="bi-arrow-up"></i>
          Voltar
        </router-link>
        <ConfirmActionDialog
          v-show="showConfirmActionDialog"
          @dialogCompleted="handleDialogCompleted"
          :mainText="i18n.translate('views.workExecution.noPhotosWarning')"
          :confirmActionText="i18n.translate('actions.confirmSave')"
          :cancelActionText="
            i18n.translate('views.workExecution.backAddPhotos')
          "
        />
        <form
          v-show="!showConfirmActionDialog"
          class="my-4"
          @submit.prevent="submitAction"
          novalidate
        >
          <Camera ref="camera" idleText="Tirar fotos (antes do serviço)" />
          <button class="my-4 btn btn-success">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmActionDialog from '../components/ConfirmActionDialog';
import Camera from '../components/Camera';
import productDao from '../dao/product_dao';
import workExecutionDao from '../dao/work_execution_dao';
import attachmentDao from '../dao/attachment_dao';
import i18n from '../i18n/i18n';
import store from '../store/store';
import { useToast } from 'vue-toastification';

export default {
  name: 'WorkExecutionStepOne',
  components: {
    ConfirmActionDialog,
    Camera,
  },
  props: {
    productId: String,
  },
  setup() {
    return { toast: useToast() };
  },
  created() {
    productDao.product(this.productId, (product) => (this.product = product));
  },
  data() {
    return {
      showConfirmActionDialog: false,
      product: {
        data: {
          name: '',
        },
      },
      i18n: i18n,
    };
  },
  methods: {
    submitAction() {
      if (this.$refs.camera.anyFiles()) {
        this.saveWorkExecution();
      } else {
        this.showConfirmActionDialog = true;
        return;
      }
    },
    handleDialogCompleted(confirmed) {
      if (confirmed) this.saveWorkExecution();
      else this.showConfirmActionDialog = false;
    },
    saveWorkExecution() {
      store.commit('activateFullscreenOverlay');

      const workExecution = {
        productId: this.product._id,
      };
      workExecutionDao.startWorkExecution(workExecution, (workExecutionId) => {
        if (this.$refs.camera.anyFiles()) {
          const metadata = {
            ownerDocId: workExecutionId,
            labels: ['beforeWorkExecution'],
          };
          attachmentDao.saveAttachments(
            metadata,
            this.$refs.camera.getFileBlobs(),
            this.afterSaveWorkExecution
          );
        } else {
          this.afterSaveWorkExecution();
        }
      });
    },
    afterSaveWorkExecution() {
      store.commit('deactivateFullscreenOverlay');
      this.toast.success(i18n.translate('actions.saved'));
      this.$router.push({
        name: 'ShowProduct',
        params: { productId: this.product._id },
      });
    },
  },
};
</script>
