import Dao from './dao';
import { existingRecordDefaultFields } from './utils';
import dataTypes from '../data_types/data_types';

export class WorkReportDao extends Dao {
  constructor(workspaceRemoteDb, attachmentsRemoteDb) {
    super();

    this.remote = workspaceRemoteDb;
    this.attachmentsRemote = attachmentsRemoteDb;
  }

  db() {
    return this.remote.db();
  }

  // startDate - ISO 8601 string
  // endDate - ISO 8601 string
  async completedWork(startDate, endDate, done) {
    try {
      const res1 = await this.remote.db().find({
        selector: {
          productId: { $gt: null },
          createdAt: { $gte: startDate, $lte: endDate },
        },
        use_index: 'idx_product_id_created_at',
        limit: 99999,
      });
      const res2 = await this.remote.db().find({
        selector: {
          replacedProductId: { $gt: null },
          createdAt: { $gte: startDate, $lte: endDate },
        },
        use_index: 'idx_replaced_product_id_created_at',
        limit: 99999,
      });
      const res3 = await this.remote.db().find({
        selector: {
          installedAt: { $gte: startDate, $lte: endDate },
        },
        use_index: 'idx_installed_at',
        limit: 99999,
      });

      let res = res1.docs.concat(res2.docs, res3.docs);
      res = res.filter(
        (work) => work.status === undefined || work.status === 'completed'
      );

      done(res);
    } catch (err) {
      console.log(err);
    }
  }

  async updateWork(id, attrsToUpdate, done) {
    try {
      let work = await this.remote.db().get(id);
      // Safeguard to avoid the passed in object overwriting meta-atributes.
      delete attrsToUpdate._id;
      delete attrsToUpdate._rev;
      Object.assign(work, attrsToUpdate);

      dataTypes.coerceRecursively(work);
      existingRecordDefaultFields(work);
      await this.remote.db().put(work);

      done(id, true);
    } catch (err) {
      console.log(err);
      done(id, false);
    }
  }

  async products(productIds, done) {
    try {
      let res = await this.remote.db().allDocs({
        include_docs: true,
        keys: productIds,
      });
      done(res.rows.map((el) => el.doc));
    } catch (err) {
      console.log(err);
    }
  }

  async attachmentObjects(ownerDocIds, done) {
    try {
      let res = await this.attachmentsRemote.db().find({
        selector: {
          ownerDocId: {
            '$in': ownerDocIds,
          },
        },
        use_index: 'idx_owner_doc_id',
        limit: 99999,
      });

      done(res.docs);
    } catch (err) {
      console.log(err);
    }
  }

  async attachmentFiles(attachmentIds, done) {
    try {
      let res = await this.attachmentsRemote.db().allDocs({
        include_docs: true,
        attachments: true,
        keys: attachmentIds,
      });
      done(
        res.rows
          .filter((el) => el.error !== 'not_found' && el.value.deleted !== true)
          .map((el) => el.doc)
      );
    } catch (err) {
      console.log(err);
    }
  }
}
