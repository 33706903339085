import PouchDb from 'pouchdb';
import store from '../store/store';

class Session {
  db() {
    return new PouchDb('session');
  }

  currentUser() {
    return this.db().get('session');
  }

  loadIntoStore() {
    return this.currentUser().then((user) => store.commit('loadUser', user));
  }

  async selectWorkspace(workspace, done) {
    try {
      let user = await this.db().get('session');
      Object.assign(user, {
        currentWorkspace: workspace,
        currentRoles: user.arborRoles[workspace],
      });
      await this.db().put(user);
      done();
    } catch (err) {
      console.log(err);
    }
  }

  signOut() {
    store.commit('deleteUser');
    return this.db().destroy();
  }
}

export default new Session();
