import camelCase from 'camelcase';
import { dig } from '@kaspernj/object-digger';

const i18n = {
  translate(translationPath, optsToOverwrite = {}) {
    if (typeof translationPath === 'string')
      translationPath = translationPath.split('.');

    const options = Object.assign(
      {
        convertPathToCamelCase: true,
      },
      optsToOverwrite
    );

    if (options.convertPathToCamelCase)
      translationPath = translationPath.map((p) => camelCase(p));
    const translation = dig(this.translations, ...translationPath);

    return translation
      ? translation
      : `${translationPath.join('.')} not found.`;
  },
  t(translationPath, optsToOverwrite = {}) {
    return this.translate(translationPath, optsToOverwrite);
  },
  translations: {
    thirdParty: {
      filepond: {
        labelIdle:
          '<span class="filepond--label-action">Tirar ou escolher fotos</span>',
      },
    },
    collections: {
      productTypes: {
        beltConveyorSystem: 'Transportador de correia',
        beltConveyorFramesAndFootbridges:
          'Passarelas e estruturas do transportador de correia',
        bearingSet: 'Mancais',
        motor: 'Motor',
        gearmotor: 'Redutor',
        electromagneticBrake: 'Motorredutor',
        conveyorBelt: 'Correia transportadora',
        feedChute: 'Chute de carga',
        dischargeChute: 'Chute de descarga',
        tailPulley: 'Tambor de retorno',
        takeUpPulley: 'Tambor de esticamento',
        headPulley: 'Tambor de acionamento',
        idlerSet: 'Rolos e cavaletes',
        tailDustSeal: 'Vedação traseira',
        sideDustSeal: 'Vedação lateral',
        conveyorBeltCover: 'Cobertura de correia',
        impactBed: 'Mesa de impacto',
        dustSealingBed: 'Mesa de vedação',
        primaryBeltCleaner: 'Raspador primário',
        secondaryBeltCleaner: 'Raspador secundário',
        diagonalPlow: 'Limpador diagonal',
        vPlow: 'Limpador em V',
        magneticSeparator: 'Separador eletromagnético',
        vibratingSieve: 'Peneira vibratória',
      },
      productOperatingConditions: {
        good: 'Operante',
        poor: 'Operante com observaçōes',
        inoperative: 'Inoperante',
      },
      workTypes: {
        lubrificacao: 'Lubrificação',
        emenda: 'Emenda',
        reparoComManchao: 'Reparo com manchão',
        grampeamento: 'Grampeamento',
        remendo: 'Remendo',
        instalacaoRevestimento: 'Instalação de revestimento',
        substituicaoRevestimento: 'Substituição de revestimento',
        substituicaoPartes: 'Substituição de partes',
        instalacaoJanelaInspecao: 'Instalação de janela de inspeção',
        reformaEixo: 'Reforma do eixo',
        reformaCorpo: 'Reforma do corpo',
        balanceamento: 'Balanceamento',
        substituicaoRolo: 'Substituição do rolo',
        reparoCavalete: 'Reparo no cavalete',
        substituicaoCavalete: 'Substituição do cavalete',
        substituicaoVedacao: 'Substituição do elemento de vedação',
        instalacaoFixacao: 'Instalação de elementos de fixação',
        substituicaoFixacao: 'Substituição de elementos de fixação',
        reparoChapaBase: 'Reparo da chapa base',
        substituicaoChapaCobertura: 'Substituição da chapa de cobertura',
        substituicaoEstrutura: 'Substituição da estrutura',
        substituicaoBarrasImpacto: 'Substituição das barras de impacto',
        reparoEstrutura: 'Reparo na estrutura',
        substituicaoRolosCarga: 'Substituição dos rolos de carga',
        ajuste: 'Ajuste',
        substituicaoLaminas: 'Substituição da lâmina',
        substituicaoTensor: 'Substituição do sistema tensor',
        substituicaoEixos: 'Substituição de eixos',
        outroServico: 'Outro serviço',
      },
    },
    models: {
      shared: {
        parentId: 'Produto pai',
        name: 'Nome',
        brand: 'Marca',
        model: 'Modelo',
        partNumber: 'Part number',
        createdAt: 'Criado em',
        installedAt: 'Instalado em',
        updatedAt: 'Atualizado em',
        createdBy: 'Criado por (usuário)',
        updatedBy: 'Atualizado por (usuário)',
        createdByFullName: 'Criado por',
        updatedByFullName: 'Atualizado por',
      },
      beltConveyorSystem: {
        carriedMaterialType: 'Material transportado',
        beltWidth: 'Largura da correia (polegadas)',
        pulleyDiameter: 'Diâmetro do tambor (mm)',
        speed: 'Velocidade da correia (m/s)',
        operatingTemperature: 'Temperatura de operação (°C)',
        peakTemperature: 'Temperatura de pico (°C)',
        productionCapacity: 'Capacidade de produção (toneladas/hora)',
      },
      conveyorBelt: {
        width: 'Largura (polegadas)',
        length: 'Comprimento (mm)',
      },
      motor: {
        power: 'Potência (cv)',
        revolutions: 'Rotação (rpm)',
        electricTension: 'Tensão (V)',
        poles: 'Número de pólos',
      },
    },
    validations: {
      shared: {
        formErrorsSummary:
          'Há erros no formulário. Corrija os campos com problemas e tente novamente.',
        mustBeFilled: 'deve ser preenchido',
        mustBeSelected: 'deve ser selecionado',
        atLeastOneMustBeSelected: 'pelo menos um deve ser selecionado',
      },
      inspection: {
        productConditionRequiresObservations:
          'como o produto não está em perfeito estado de funcionamento, é necessário você dar detalhes do que está acontecendo',
      },
      workExecution: {
        workExecutedRequiresObservations:
          "como você selecionou 'Outro serviço', é necessário descrever resumidamente o que foi feito",
      },
    },
    actions: {
      saved: 'Salvo com sucesso.',
      confirmSave: 'Salvar assim mesmo',
      unknownSaveError:
        'Não foi possível salvar. Verifique sua conexão e tente novamente.',
      authSignedIn: 'Autenticado com sucesso.',
      authWorkspaceSelected: 'Area de trabalho selecionada com sucesso.',
      authSignedOut: 'Você saiu do sistema.',
      authNotSignedInError:
        'Você precisa se autenticar antes de acessar essa tela.',
      authUnauthorizedError: 'Você não tem permissão para acessar essa tela.',
      authNoWorkspaceError:
        'Voce deve primeiro selecionar uma área de trabalho.',
      authWrongCredentialsError: 'Usuário ou senha incorreta.',
      authNetworkError:
        'Não foi possível se conectar ao servidor.<br />Verifique sua conexão com a Internet e tente novamente por favor.',
      authUnknownError:
        'Ocorreu um erro, tente novamente por favor.<br />Se esse problema persistir, contate o suporte.',
    },
    components: {
      workExecutionList: {
        startedTitle: 'Registros de execução iniciados',
        completedTitle: 'Registros de execução concluídos',
      },
    },
    views: {
      workExecution: {
        noPhotosWarning:
          'É preferível que um registro de execução tenha pelo menos uma foto.<br />Tem certeza de que deseja salvar o registro sem adicionar fotos?',
        backAddPhotos: 'Voltar e adicionar fotos',
      },
      productReplacement: {
        noPhotosWarning:
          'É preferível que ao substituir um produto você tire pelo menos uma foto.<br />Tem certeza de que deseja salvar o registro sem adicionar fotos?',
        backAddPhotos: 'Voltar e adicionar fotos',
        alreadyReplaced:
          'Esse produto já foi substituído e não está mais ativo.',
      },
    },
    workspaces: {
      developmentWorkspace: 'Desenvolvimento (local)',
      nexaJfWorkspace: 'Nexa (Juiz de Fora - MG)',
      lumanSjcWorkspace: 'Luman PSI (São José dos Campos - SP)',
      votorantimCimentosSantaIsabelWorkspace:
        'Votorantim Cimentos (Santa Isabel - SP)',
      pedreiraEmbuWorkspace: 'Pedreira Embu (Embu das Artes - SP)',
      arcelorMittalJoaoMonlevadeWorkspace:
        'Arcelor Mittal (João Monlevade - MG)',
    },
  },
};

export default i18n;
