import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/scss/main.scss';
import 'bootstrap';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import './registerServiceWorker';
import Toast from 'vue-toastification';
import store from './store/store';

const app = createApp(App);

app.use(router);
app.use(store);

const vueToastificationOpts = {
  position: 'bottom-center',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true,
};
app.use(Toast, vueToastificationOpts);

app.mount('#app');
