<template>
  <div class="container-fluid" style="min-height: 1000px">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="my-3">{{ product.data.name }}</h1>
        <router-link
          v-if="product.parentId"
          :to="{
            name: 'ChildProductList',
            params: { productId: product.parentId },
          }"
          class="mb-3 btn btn-outline-dark"
        >
          <i class="bi-arrow-up"></i>
          Voltar
        </router-link>
        <router-link
          v-else
          :to="{
            name: 'RootProductList',
          }"
          class="mb-3 btn btn-outline-dark"
        >
          <i class="bi-arrow-up"></i>
          Voltar
        </router-link>
        <div
          v-show="isProductReplacementPending"
          class="mb-5 alert alert-warning"
        >
          <span class="d-block"
            >A substituição desse produto já foi iniciada.</span
          >
          <router-link
            :to="{
              name: 'ProductReplacementStepOne',
              params: { productId: productId },
            }"
            class="mt-2 btn btn-outline-dark"
          >
            <i class="d-inline-block me-2 bi-arrow-left-right"></i>
            Continuar substituição
          </router-link>
        </div>
        <div class="mb-5">
          <div
            class="mb-3"
            v-for="(labelAndValue, index) in productLabelsAndValues"
            :key="index"
          >
            <span style="width: 200px" class="d-inline-block">{{
              labelAndValue[0] + ': '
            }}</span>
            <span class="d-inline-block">{{ labelAndValue[1] }}</span>
          </div>
        </div>
        <button
          v-show="currentReportType !== ''"
          @click="showReportType('')"
          class="mb-3 btn btn-outline-dark"
        >
          <i class="me-2 bi-arrow-up"></i>
          Ver outro tipo de relatório
        </button>
        <div v-show="currentReportType === '' && product.parentId">
          <h3 class="mb-3">Relatórios associados</h3>
          <button
            @click="showReportType('InspectionList')"
            class="d-block mb-3 btn btn-outline-dark text-left"
            style="min-width: 300px"
          >
            <i class="d-inline-block me-2 bi-eye-fill text-success"></i>
            Inspeçōes concluídas
          </button>
          <button
            @click="showReportType('StartedWorkExecutionList')"
            class="d-block mb-3 btn btn-outline-dark text-left"
            style="min-width: 300px"
          >
            <i class="d-inline-block me-2 bi-wrench text-warning"></i>
            Registros de execução iniciados
          </button>
          <button
            @click="showReportType('CompletedWorkExecutionList')"
            class="d-block mb-3 btn btn-outline-dark text-left"
            style="min-width: 300px"
          >
            <i class="d-inline-block me-2 bi-wrench text-success"></i>
            Registros de execução concluídos
          </button>
        </div>
        <InspectionList
          v-if="currentReportType === 'InspectionList'"
          :productId="productId"
        />
        <WorkExecutionList
          v-if="currentReportType === 'StartedWorkExecutionList'"
          :productId="productId"
        />
        <WorkExecutionList
          v-if="currentReportType === 'CompletedWorkExecutionList'"
          :productId="productId"
          workExecutionStatus="completed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import arborDao from '../dao/arbor_dao';
import productDao from '../dao/product_dao';
import productReplacementDao from '../dao/product_replacement_dao';
import productViewHelper from '../view_helpers/product_view_helper';
import WorkExecutionList from '../components/WorkExecutionList';
import InspectionList from '../components/InspectionList';

export default {
  name: 'ShowProduct',
  components: {
    WorkExecutionList,
    InspectionList,
  },
  props: {
    productId: String,
  },
  created() {
    productReplacementDao.pendingProductReplacement(
      this.productId,
      (pendingProductReplacement) =>
        (this.isProductReplacementPending = pendingProductReplacement
          ? true
          : false)
    );

    productDao.product(this.productId, (product) => {
      this.product = product;

      arborDao.getObjById(this.product.schemaId, (productSchema) => {
        this.productSchema = productSchema;

        if (this.product.parentId)
          productDao.product(this.product.parentId, (parentProduct) => {
            this.parentProduct = parentProduct;
            this.buildProductLabelsAndValues();
          });
        else this.buildProductLabelsAndValues();
      });
    });
  },
  data() {
    return {
      product: {
        parentId: 'id',
        data: {
          name: '',
        },
      },
      isProductReplacementPending: false,
      parentProduct: null,
      productSchema: { schema: { properties: {} } },
      productLabelsAndValues: [['', '']],
      currentReportType: '',
    };
  },
  methods: {
    buildProductLabelsAndValues() {
      this.productLabelsAndValues = productViewHelper.productLabelsAndValues(
        this.product,
        this.productSchema,
        this.parentProduct
      );
    },
    showReportType(reportType) {
      this.currentReportType = reportType;
    },
  },
};
</script>
