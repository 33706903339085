<template>
  <div v-if="isActive" id="fullscreen-overlay">
    <div
      id="fullscreen-overlay-loading"
      class="spinner-border text-dark"
      role="status"
    ></div>
  </div>
</template>

<script>
import store from '../store/store';

export default {
  name: 'FullscreenOverlay',
  data() {
    return {};
  },
  computed: {
    isActive() {
      return store.state.fullscreenOverlayActive;
    },
  },
};
</script>
