<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-4">
        <h1 class="mt-3">Selecione uma área de trabalho</h1>
        <div class="mt-3 bd-callout bd-callout-info">
          <i class="bi bi-info-square-fill d-inline-block me-1"></i>
          As áreas de trabalho garantem que os dados de diferentes clientes e
          unidades fiquem separados. Em geral, uma área de trabalho é
          equivalente a uma planta ou unidade do cliente (exemplo: Nexa, Juiz de
          Fora - MG).
        </div>
        <ul class="list-unstyled mt-5">
          <li
            class="d-block mt-4"
            v-for="workspace in user.arborWorkspaces"
            :key="workspace"
          >
            <a
              class="d-block btn btn-outline-primary"
              @click.stop.prevent="selectWorkspace(workspace)"
              >{{ i18n.t(['workspaces', workspace]) }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';
import { useToast } from 'vue-toastification';
import store from '../store/store';
import session from '../auth/session';

export default {
  name: 'WorkspaceList',
  setup() {
    return { toast: useToast(), i18n: i18n };
  },
  data() {
    return {
      user: store.state.user,
    };
  },
  methods: {
    selectWorkspace(workspace) {
      session.selectWorkspace(workspace, () => {
        this.toast.success(i18n.translate('actions.authWorkspaceSelected'));
        this.$router.push({
          name: 'RootProductList',
        });
      });
    },
  },
};
</script>
