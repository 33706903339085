import { v4 as uuidv4 } from 'uuid';
import WorkspaceDao from './workspace_dao';
import { newRecordDefaultFields, existingRecordDefaultFields } from './utils';
import dataTypes from '../data_types/data_types';

class ProductReplacementDao extends WorkspaceDao {
  constructor() {
    super();
  }

  async pendingProductReplacement(productId, done) {
    try {
      let res = await this.db().find({
        selector: {
          replacedProductId: productId,
        },
        use_index: 'idx_replaced_product_id',
      });

      done(
        res.docs.filter(
          (doc) =>
            doc._id.startsWith('product_replacement::') &&
            ['started', 'picturesTaken'].includes(doc.status)
        )[0]
      );
    } catch (err) {
      console.log(err);
    }
  }

  async startProductReplacement(productReplacement, done) {
    try {
      const id = `product_replacement::${uuidv4()}`;
      Object.assign(productReplacement, {
        _id: id,
        newProductId: null,
        workOrder: null,
        observations: null,
        status: 'started',
      });
      dataTypes.coerceRecursively(productReplacement);
      newRecordDefaultFields(productReplacement);
      await this.db().put(productReplacement);
      done(id);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ProductReplacementDao();
