import dataTypes from '../data_types/data_types';
import appHelper from '../helpers/app_helper';

export class WorkReport {
  constructor(workReportDao, workReportDoneCb) {
    this.workReportDao = workReportDao;
    this.workReportDoneCb = workReportDoneCb;
    this.rootProducts = {};
    this.products = [];
    this.completedWork = {};
    this.attachments = {};
  }

  sortedRootProducts() {
    return Object.values(this.rootProducts).sort((a, b) => {
      var nameA = a.data.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.data.name.toUpperCase(); // ignore upper and lowercase

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  }

  rootProductIds() {
    return this.products.map((p) => p.parentId);
  }

  productIds() {
    return Object.keys(this.completedWork);
  }

  completedWorkIds() {
    return Object.values(this.completedWork)
      .flat()
      .map((w) => w._id);
  }

  buildCompletedWorkMap(completedWork) {
    completedWork.forEach((w) => {
      switch (appHelper.objTypeFromId(w._id)) {
        case 'inspection':
          this.completedWork[w.productId] = dataTypes.pushToMaybeArray(
            this.completedWork[w.productId],
            w
          );
          return;
        case 'work_execution':
          this.completedWork[w.productId] = dataTypes.pushToMaybeArray(
            this.completedWork[w.productId],
            w
          );
          return;
        case 'product_replacement':
          this.completedWork[w.replacedProductId] = dataTypes.pushToMaybeArray(
            this.completedWork[w.replacedProductId],
            w
          );
          return;
        case 'products':
          // Here we are actually dealing with a document of type products (e.g. products::041672c7-a7e6-4b52-9605-9b9bbe20fa18).
          // There is no special type of document to indicate the installation of a new product. To check for newly installed
          // products, what we do is to search for products whose installedAt field is within the relevant period.
          // Besides that, we also check if the document has a parentId. The absence of a parentId means we are dealing with
          // a root product. In this case, we should ignore the completed work altogether.
          if (w.parentId) {
            this.completedWork[w._id] = dataTypes.pushToMaybeArray(
              this.completedWork[w._id],
              w
            );
          }
          return;
        default:
          return;
      }
    });
  }

  buildAttachmentsMap(attachments) {
    attachments.forEach((attachment) => {
      this.attachments[attachment.ownerDocId] = dataTypes.pushToMaybeArray(
        this.attachments[attachment.ownerDocId],
        attachment
      );
    });
  }

  buildReport(startDate, endDate) {
    this.workReportDao.completedWork(startDate, endDate, (completedWork) => {
      this.buildCompletedWorkMap(completedWork);

      this.workReportDao.attachmentObjects(
        this.completedWorkIds(),
        (attachmentObjects) => {
          this.buildAttachmentsMap(attachmentObjects);

          this.workReportDao.products(this.productIds(), (products) => {
            this.products = products.filter((p) => p);

            this.workReportDao.products(
              this.rootProductIds(),
              (rootProducts) => {
                rootProducts
                  .filter((p) => p)
                  .forEach((p) => {
                    this.rootProducts[p._id] = p;
                  });
                this.linkObjects();

                this.workReportDoneCb();
              }
            );
          });
        }
      );
    });
  }

  linkObjects() {
    Object.values(this.completedWork)
      .flat()
      .forEach((w) => (w.attachments = this.attachments[w._id]));

    const productsById = Object.fromEntries(
      this.products.map((p) => [p._id, p])
    );

    this.products.forEach((p) => {
      p.completedWork = this.completedWork[p._id] || [];

      this.rootProducts[p.parentId].products = dataTypes.pushToMaybeArray(
        this.rootProducts[p.parentId].products,
        p
      );
    });
  }
}
