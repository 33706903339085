import { v4 as uuidv4 } from 'uuid';
import WorkspaceDao from './workspace_dao';
import { newRecordDefaultFields } from './utils';
import dataTypes from '../data_types/data_types';

class InspectionDao extends WorkspaceDao {
  constructor() {
    super();
  }

  async inspections(productId, done) {
    try {
      let res = await this.db().find({
        selector: {
          productId: productId,
        },
        use_index: 'idx_product_id',
      });

      done(res.docs.filter((doc) => doc._id.startsWith('inspection::')));
    } catch (err) {
      console.log(err);
    }
  }

  async saveInspection(inspection, done) {
    try {
      const id = `inspection::${uuidv4()}`;
      Object.assign(inspection, {
        _id: id,
      });
      dataTypes.coerceRecursively(inspection);
      newRecordDefaultFields(inspection);
      await this.db().put(inspection);
      done(id);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new InspectionDao();
