<template>
  <div class="row justify-content-center">
    <div class="col col-md-6">
      <div class="card arbor-card mb-4">
        <div class="card-header arbor-card-header">
          <h3 class="d-inline-block me-3">{{ title }}</h3>
          <div
            v-if="overallStatus === 'working'"
            class="
              d-inline-block
              align-baseline
              spinner-border spinner-border-sm
              text-dark
            "
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="card-body">
          <div v-if="syncMode == 'pull' || syncMode == 'pullPush'">
            <span>{{ pullMsg }}</span>
            <span class="d-inline-block ms-2" :class="pullStatusMsgClasses">{{
              pullStatusMsg
            }}</span>
          </div>
          <div v-if="syncMode == 'push' || syncMode == 'pullPush'">
            <span>{{ pushMsg }}</span>
            <span class="d-inline-block ms-2" :class="pushStatusMsgClasses">{{
              pushStatusMsg
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import syncMixin from '../mixins/sync_mixin';

export default {
  name: 'SyncPanel',
  mixins: [syncMixin],
  emits: ['update:overallSyncStatus'],
  props: {
    syncMode: {
      type: String,
      required: true,
    },
    db: {
      type: Object,
      required: true,
    },
    remoteDb: {
      type: Object,
    },
    overallSyncStatus: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    pullActiveMsg: String,
    pushActiveMsg: String,
  },
  data() {
    return {
      pullMsg: 'Download: -',
      pullStatus: 'waiting',
      pushMsg: 'Upload: -',
      pushStatus: 'waiting',
    };
  },
  computed: {
    overallStatus() {
      return this.coalesceSyncStatuses(this.pullStatus, this.pushStatus);
    },
    pullStatusMsg() {
      return this.statusMsg(this.pullStatus);
    },
    pullStatusMsgClasses() {
      return this.syncStatusClasses(this.pullStatus);
    },
    pushStatusMsg() {
      return this.statusMsg(this.pushStatus);
    },
    pushStatusMsgClasses() {
      return this.syncStatusClasses(this.pushStatus);
    },
  },
  watch: {
    overallStatus(newStatus, oldStatus) {
      this.$emit('update:overallSyncStatus', newStatus);
    },
  },
  methods: {
    statusMsg(status) {
      if (status === 'error') return 'Falhou';
      else if (status === 'complete') return 'Ok';
      else return '';
    },
    sync() {
      switch (this.syncMode) {
        case 'pull':
          this.pushStatus = 'complete';
          this.pull();
          break;
        case 'push':
          this.pullStatus = 'complete';
          this.push();
          break;
        case 'pullPush':
          this.pull();
          this.push();
          break;
      }
    },
    pull() {
      this.pullMsg = this.pullActiveMsg || 'Baixando dados do servidor...';
      this.pullStatus = 'working';
      this.db.startPull(this.remoteDb, this.onPullComplete, this.onPullError);
    },
    push() {
      this.pushMsg =
        this.pushActiveMsg || 'Carregando dados para o servidor...';
      this.pushStatus = 'working';
      this.db.startPush(this.remoteDb, this.onPushComplete, this.onPushError);
    },
    onPullComplete() {
      this.pullStatus = 'complete';
    },
    onPullError(error) {
      console.log(error);
      this.pullStatus = 'error';
    },
    onPushComplete() {
      this.pushStatus = 'complete';
    },
    onPushError(error) {
      console.log(error);
      this.pushStatus = 'error';
    },
  },
};
</script>
