<!--
  Here we are actually dealing with a document of type products (e.g. products::041672c7-a7e6-4b52-9605-9b9bbe20fa18).
  There is no special type of document to indicate the installation of a new product. To check for newly installed
  products, what we do is to search for products whose installedAt field is within the relevant period.
-->
<template>
  <p>
    <span class="d-block fst-italic">
      <i class="d-inline-block me-1 bi-calendar3"></i>
      {{
        appViewHelper.formatDateTime(product.installedAt, 'DATE_SHORT')
      }}</span
    >
    <span class="fw-bold">
      <i class="d-inline-block me-1 bi-star-fill"></i>
      Instalação de equipamento
    </span>
  </p>
  <p>O equipamento {{ product.data.name }} foi instalado.</p>
</template>

<script>
import appViewHelper from '../view_helpers/app_view_helper';
import i18n from '../i18n/i18n';

export default {
  name: 'WorkReportProductInstallation',
  props: {
    work: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      appViewHelper,
      i18n,
    };
  },
  created() {
    this.product = this.work;
  },
  data() {
    return {
      product: null,
    };
  },
};
</script>
