<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-4">
        <h1 class="mt-3">Novo produto</h1>
        <h4 class="mt-1">passo 1 de 2</h4>
        <form class="mt-4" @submit.prevent="submitAction">
          <label for="product-type-select" class="form-label"
            >Tipo de produto</label
          >
          <select
            id="product-type-select"
            class="form-select"
            v-model="productType"
          >
            <option
              v-for="type in productTypes"
              :key="type.value"
              :value="type.value"
            >
              {{ type.text }}
            </option>
          </select>
          <button class="mt-4 btn btn-success" :class="submitBtnClasses">
            Continuar
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import dao from '../dao/arbor_dao';
import store from '../store/store';
import authorization from '../auth/authorization';

export default {
  name: 'CreateProductStepOne',
  created() {
    dao.arborCollectionForSelect(
      'product_types',
      (res) => (this.productTypes = this.productTypes.concat(res))
    );
  },
  data() {
    return {
      productType: '',
      productTypes: [{ text: '', value: '' }],
    };
  },
  methods: {
    submitAction() {
      if (this.hasErrors) return;

      this.$router.push({
        name: 'CreateProductStepTwo',
        params: { productType: this.productType },
        query: { prefillInstalledAt: !authorization.isAdmin(store.state.user) },
      });
    },
  },
  computed: {
    hasErrors() {
      return this.productType === '';
    },
    submitBtnClasses() {
      return {
        disabled: this.hasErrors,
      };
    },
  },
};
</script>

<style scoped></style>
