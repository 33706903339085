<template>
  <h4>
    {{
      i18n.translate(`components.workExecutionList.${workExecutionStatus}Title`)
    }}
  </h4>
  <div
    v-if="status === 'loading'"
    class="spinner-border spinner-border-sm text-dark"
  ></div>
  <div
    v-show="status === 'done' && workExecutions.length > 0"
    v-for="workExecution in workExecutions"
    :key="workExecution._id"
    class="row justify-content-center"
  >
    <div class="col">
      <div class="card arbor-card mb-4">
        <div class="card-body">
          <router-link
            v-if="workExecutionStatus === 'started'"
            :to="{
              name: 'WorkExecutionStepTwo',
              params: { workExecutionId: workExecution._id },
            }"
            class="btn btn-outline-dark me-2"
          >
            <i class="bi-wrench"></i>
          </router-link>
          Criado em: {{ appViewHelper.formatDateTime(workExecution.createdAt) }}
          <span v-if="workExecutionStatus === 'completed'"
            ><br />Concluído em:
            {{ appViewHelper.formatDateTime(workExecution.updatedAt) }}
            <br />Serviços executados:
            {{
              workExecutionViewHelper.printWorkExecuted(
                workExecution.workExecuted
              )
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div v-show="status === 'done' && workExecutions.length === 0">
    Ainda não há relatórios desse tipo.
  </div>
</template>

<script>
import workExecutionDao from '../dao/work_execution_dao';
import appViewHelper from '../view_helpers/app_view_helper';
import workExecutionViewHelper from '../view_helpers/work_execution_view_helper';
import i18n from '../i18n/i18n';

export default {
  name: 'WorkExecutionList',
  props: {
    productId: {
      type: String,
      required: true,
    },
    workExecutionStatus: {
      type: String,
      default: 'started',
    },
  },
  setup() {
    return {
      appViewHelper,
      workExecutionViewHelper,
      i18n,
    };
  },
  created() {
    workExecutionDao.workExecutions(
      this.productId,
      this.workExecutionStatus,
      (workExecutions) => {
        this.workExecutions = workExecutions;
        this.status = 'done';
      }
    );
  },
  data() {
    return {
      status: 'loading',
      workExecutions: [],
    };
  },
};
</script>
