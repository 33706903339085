<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Novo produto</h1>
        <h4 class="mt-1">
          {{ i18n.translate(`collections.productTypes.${this.productType}`) }}
        </h4>
        <h4 v-if="!isReplacingAProduct" class="mt-1">passo 2 de 2</h4>
        <router-link
          v-if="isReplacingAProduct"
          :to="{
            name: 'ShowProduct',
            params: { productId: replacedProductId },
          }"
          class="mt-2 btn btn-outline-dark"
        >
          <i class="bi-arrow-up"></i>
          Voltar para o produto sendo substituído
        </router-link>
        <div v-if="isReplacingAProduct" class="mt-3 alert alert-warning">
          Esse novo produto sendo criado <b>substituirá</b> o produto
          <b>{{ replacedProduct.data.name }}</b> (registrado em
          {{ appViewHelper.formatDateTime(replacedProduct.createdAt) }}).
        </div>
        <form
          class="form my-4"
          :class="formClasses"
          @submit.prevent="submitAction"
        >
          <div class="mt-4">
            <label for="parent-id-select" class="form-label">{{
              i18n.translate('models.shared.parentId')
            }}</label>
            <select
              id="parent-id-select"
              class="form-select"
              v-model="parentId"
            >
              <option
                v-for="product in products"
                :key="product._id"
                :value="product._id"
              >
                {{ product.data.name }}
              </option>
            </select>
          </div>
          <json-forms
            :ajv="ajv"
            :data="data"
            :renderers="renderers"
            :schema="schema"
            :uischema="uiSchema"
            :validationMode="validationMode"
            @change="onChange"
          />
          <label for="installed-at-input" class="form-label mt-4">{{
            i18n.translate('models.shared.installedAt')
          }}</label>
          <datepicker
            id="installed-at-input"
            class="arbor-datepicker-input"
            v-model="installedAt"
            :locale="datepickerPtBr"
            inputFormat="dd/MM/yyyy"
            weekdayFormat="EEEEE"
            :clearable="true"
          />
          <span class="d-block mt-2 errors-summary text-danger">{{
            errorsSummary
          }}</span>
          <button class="mt-2 btn btn-success">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { createAjv } from '@jsonforms/core';
import { JsonForms, JsonFormsChangeEvent } from '@jsonforms/vue';
import {
  defaultStyles,
  mergeStyles,
  vanillaRenderers,
} from '@jsonforms/vue-vanilla';
import arborDao from '../dao/arbor_dao';
import productDao from '../dao/product_dao';
import productReplacementDao from '../dao/product_replacement_dao';
import formGen from '../form/form_gen';
import i18n from '../i18n/i18n';
import store from '../store/store';
import { useToast } from 'vue-toastification';
import Datepicker from 'vue3-datepicker';
import { ptBR as datepickerPtBr } from 'date-fns/locale';
import appViewHelper from '../view_helpers/app_view_helper';

export default defineComponent({
  name: 'CreateProductStepTwo',
  components: {
    JsonForms,
    Datepicker,
  },
  props: {
    productType: String,
    replacedProductId: {
      type: String,
      required: false,
    },
    productReplacementId: {
      type: String,
      required: false,
    },
    prefillInstalledAt: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { toast: useToast(), datepickerPtBr, appViewHelper };
  },
  data() {
    const ajv = require('ajv-errors')(createAjv());

    return {
      replacedProduct: {
        data: {
          name: '',
        },
        createdAt: '',
      },
      installedAt: null,
      parentId: null,
      products: [
        {
          _id: '',
          data: {
            name: '',
          },
        },
      ],
      i18n: i18n,
      renderers: Object.freeze([...vanillaRenderers]),
      ajv: ajv,
      data: {},
      schemaId: null,
      schema: {},
      uiSchema: {},
      validationMode: 'ValidateAndShow',
      formReady: false,
      interactedWithForm: false,
      attemptedToSave: false,
      errors: [],
    };
  },
  methods: {
    onChange(event) {
      this.interactedWithForm = true;
      this.data = event.data;
      this.errors = event.errors;
    },
    submitAction() {
      this.attemptedToSave = true;
      if (this.hasErrors) return;

      store.commit('activateFullscreenOverlay');
      const product = {
        parentId: this.parentId,
        schemaId: this.schemaId,
        data: Object.assign(formGen.genBlankSchema(this.schema), this.data),
        installedAt: this.installedAt,
      };
      productDao.saveProduct(product, (productId) => {
        if (this.isReplacingAProduct) {
          this.replacedProduct.deletedAt = new Date().toISOString();
          productDao.updateObj(this.replacedProduct, (replacedProductId) => {
            this.productReplacement.newProductId = productId;
            this.productReplacement.status = 'completed';
            productReplacementDao.updateObj(
              this.productReplacement,
              (productReplacementId) => {
                this.afterSubmitAction();
              }
            );
          });
        } else {
          this.afterSubmitAction();
        }
      });
    },
    afterSubmitAction() {
      store.commit('deactivateFullscreenOverlay');
      this.toast.success(i18n.translate('actions.saved'));
      this.$router.push({ name: 'RootProductList' });
    },
  },
  created() {
    productDao.rootProducts((rootProducts) =>
      this.products.push(...rootProducts)
    );
    arborDao.productSchema(this.productType, (productSchemaObj) => {
      formGen.enhanceSchema(productSchemaObj.schema, () => {
        this.schemaId = productSchemaObj._id;
        this.schema = productSchemaObj.schema;
        this.uiSchema = formGen.genUiSchema(productSchemaObj.schema);
        this.formReady = true;
      });
    });

    if (this.prefillInstalledAt) this.installedAt = new Date();

    if (this.isReplacingAProduct) {
      productDao.getObjById(this.replacedProductId, (replacedProduct) => {
        this.replacedProduct = replacedProduct;
        this.parentId = replacedProduct.parentId;
      });

      productReplacementDao.getObjById(
        this.productReplacementId,
        (productReplacement) => (this.productReplacement = productReplacement)
      );
    }
  },
  computed: {
    hasErrors() {
      return !this.interactedWithForm || this.errors.length > 0;
    },
    errorsSummary() {
      if (this.attemptedToSave && this.hasErrors)
        return i18n.translate('validations.shared.formErrorsSummary');
      else return '';
    },
    formClasses() {
      return {
        'd-none': !this.formReady,
        'attempted-to-save': this.attemptedToSave,
      };
    },
    isReplacingAProduct() {
      return this.replacedProductId ? true : false;
    },
  },
  provide() {
    return {
      styles: mergeStyles(defaultStyles, formGen.arborStyles),
    };
  },
});
</script>
