<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Completar substituição de produto</h1>
        <h3>{{ product.data.name }}</h3>
        <router-link
          v-show="!showConfirmActionDialog"
          :to="{
            name: 'ShowProduct',
            params: { productId: product._id },
          }"
          class="mt-2 btn btn-outline-dark"
        >
          <i class="bi-arrow-up"></i>
          Voltar para o produto
        </router-link>
        <ConfirmActionDialog
          v-show="showConfirmActionDialog"
          @dialogCompleted="handleDialogCompleted"
          :mainText="i18n.translate('views.productReplacement.noPhotosWarning')"
          :confirmActionText="i18n.translate('actions.confirmSave')"
          :cancelActionText="
            i18n.translate('views.productReplacement.backAddPhotos')
          "
        />
        <form
          v-show="!showConfirmActionDialog"
          class="my-4"
          @submit.prevent="submitAction"
          novalidate
        >
          <Camera
            ref="camera"
            idleText="Tirar fotos (depois da substituição)"
          />
          <label for="work-order-input" class="form-label"
            >Ordem de serviço</label
          >
          <input
            type="text"
            id="work-order-input"
            class="form-control"
            v-model="workOrder"
          />
          <label
            for="product-replacement-observations-text-area"
            class="mt-4 form-label"
            >Observaçōes adicionais</label
          >
          <textarea
            id="product-replacement-observations-text-area"
            class="form-control"
            rows="5"
            v-model="productReplacementObs"
          ></textarea>
          <button class="my-4 btn btn-success">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmActionDialog from '../components/ConfirmActionDialog';
import Camera from '../components/Camera';
import attachmentDao from '../dao/attachment_dao';
import productReplacementDao from '../dao/product_replacement_dao';
import productDao from '../dao/product_dao';
import i18n from '../i18n/i18n';
import store from '../store/store';
import ProductHelper from '../helpers/product_helper';
import { useToast } from 'vue-toastification';

export default {
  name: 'ProductReplacementStepTwo',
  components: {
    Camera,
    ConfirmActionDialog,
  },
  props: {
    productReplacementId: String,
  },
  setup() {
    return {
      i18n,
      toast: useToast(),
    };
  },
  created() {
    productReplacementDao.getObjById(
      this.productReplacementId,
      (productReplacement) => {
        this.productReplacement = productReplacement;

        productDao.product(
          this.productReplacement.replacedProductId,
          (product) => (this.product = product)
        );
      }
    );
  },
  data() {
    return {
      showConfirmActionDialog: false,
      productReplacement: null,
      product: {
        data: {
          name: '',
        },
      },
      workOrder: null,
      productReplacementObs: '',
    };
  },
  methods: {
    submitAction() {
      if (this.$refs.camera.anyFiles()) {
        this.updateProductReplacement();
      } else {
        this.showConfirmActionDialog = true;
        return;
      }
    },
    handleDialogCompleted(confirmed) {
      if (confirmed) this.updateProductReplacement();
      else this.showConfirmActionDialog = false;
    },
    updateProductReplacement() {
      store.commit('activateFullscreenOverlay');

      this.productReplacement.status = 'picturesTaken';
      this.productReplacement.workOrder = this.workOrder;
      this.productReplacement.observations = this.productReplacementObs;

      productReplacementDao.updateObj(
        this.productReplacement,
        (productReplacementId) => {
          if (this.$refs.camera.anyFiles()) {
            const metadata = {
              ownerDocId: productReplacementId,
              labels: ['afterProductReplacement'],
            };
            attachmentDao.saveAttachments(
              metadata,
              this.$refs.camera.getFileBlobs(),
              this.afterUpdateProductReplacement
            );
          } else {
            this.afterUpdateProductReplacement();
          }
        }
      );
    },
    afterUpdateProductReplacement() {
      store.commit('deactivateFullscreenOverlay');
      this.$router.push({
        name: 'CreateProductStepTwo',
        params: { productType: new ProductHelper(this.product).type() },
        query: {
          replacedProductId: this.product._id,
          productReplacementId: this.productReplacement._id,
          prefillInstalledAt: true,
        },
      });
    },
  },
};
</script>
