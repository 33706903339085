import { v4 as uuidv4 } from 'uuid';
import WorkspaceDao from './workspace_dao';
import { newRecordDefaultFields } from './utils';
import dataTypes from '../data_types/data_types';

class ProductDao extends WorkspaceDao {
  constructor() {
    super();
  }

  async rootProducts(done) {
    try {
      let res = await this.db().allDocs({ include_docs: true });
      done(
        res.rows
          .map((el) => el.doc)
          .filter((el) => el.parentId === null && !el.deletedAt)
      );
    } catch (err) {
      console.log(err);
    }
  }

  async childProducts(parentId, done) {
    try {
      let res = await this.db().allDocs({ include_docs: true });
      done(
        res.rows
          .map((el) => el.doc)
          .filter((el) => el.parentId === parentId && !el.deletedAt)
      );
    } catch (err) {
      console.log(err);
    }
  }

  async product(id, done) {
    try {
      let res = await this.db().get(id);
      done(res);
    } catch (err) {
      console.log(err);
    }
  }

  async saveProduct(product, done) {
    try {
      Object.assign(product, {
        _id: `products::${uuidv4()}`,
        genusId: null,
      });
      dataTypes.coerceRecursively(product);
      newRecordDefaultFields(product);
      await this.db().put(product);
      done(product._id);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ProductDao();
