<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Inspeção livre</h1>
        <h4>{{ product.data.name }}</h4>
        <router-link
          v-show="!showNoPhotosConfirmationDialog"
          :to="{
            name: 'ChildProductList',
            params: { productId: product.parentId },
          }"
          class="mt-2 btn btn-outline-dark"
        >
          <i class="bi-arrow-up"></i>
          Voltar
        </router-link>
        <div v-show="showNoPhotosConfirmationDialog" class="my-4">
          <p class="m-0">
            É preferível que uma inspeção tenha pelo menos uma foto.<br />Tem
            certeza de que deseja salvar a inspeção sem adicionar fotos?
          </p>
          <button
            class="d-block mt-4 me-4 arbor-btn btn btn-warning"
            style="min-width: 195px"
            @click="saveInspection"
          >
            Salvar assim mesmo
          </button>
          <button
            class="d-block mt-3 btn btn-outline-success"
            style="min-width: 195px"
            @click="this.showNoPhotosConfirmationDialog = false"
          >
            Voltar e adicionar fotos
          </button>
        </div>
        <form
          v-show="!showNoPhotosConfirmationDialog"
          class="my-4"
          @submit.prevent="submitAction"
          novalidate
        >
          <Camera ref="camera" />
          <label for="work-order-input" class="form-label"
            >Ordem de serviço</label
          >
          <input
            type="text"
            id="work-order-input"
            class="form-control"
            v-model="workOrder"
          />
          <label for="product-op-condition-select" class="mt-4 form-label"
            >Estado de funcionamento do produto</label
          >
          <select
            id="product-op-condition-select"
            class="form-select"
            v-model="productOperatingCondition"
          >
            <option
              v-for="opCondition in operatingConditions"
              :key="opCondition.value"
              :value="opCondition.value"
            >
              {{ opCondition.text }}
            </option>
          </select>
          <div class="text-danger">
            {{ productOperatingConditionError }}
          </div>
          <label
            for="product-op-condition-observations-text-area"
            class="mt-4 form-label"
            >Descreva resumidamente o problema</label
          >
          <textarea
            id="product-op-condition-observations-text-area"
            class="form-control"
            rows="5"
            v-model="productOperatingConditionObs"
          ></textarea>
          <div class="text-danger">
            {{ productOperatingConditionObsError }}
          </div>
          <button class="my-4 btn btn-success">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Camera from '../components/Camera';
import arborDao from '../dao/arbor_dao';
import productDao from '../dao/product_dao';
import inspectionDao from '../dao/inspection_dao';
import attachmentDao from '../dao/attachment_dao';
import i18n from '../i18n/i18n';
import store from '../store/store';
import { useToast } from 'vue-toastification';

export default {
  name: 'Inspection',
  components: {
    Camera,
  },
  props: {
    productId: String,
  },
  data() {
    return {
      product: {
        data: {
          name: '',
        },
      },
      workOrder: null,
      productOperatingCondition: '',
      productOperatingConditionError: '',
      operatingConditions: [],
      productOperatingConditionObs: '',
      productOperatingConditionObsError: '',
      showNoPhotosConfirmationDialog: false,
    };
  },
  setup() {
    return { toast: useToast() };
  },
  created() {
    arborDao.arborCollectionForSelect(
      'product_operating_conditions',
      (res) => (this.operatingConditions = this.operatingConditions.concat(res))
    );

    productDao.product(this.productId, (product) => (this.product = product));
  },
  methods: {
    clearPreviousErrors() {
      this.productOperatingConditionError = '';
      this.productOperatingConditionObsError = '';
    },
    validate() {
      this.clearPreviousErrors();

      if (this.productOperatingCondition === '')
        this.productOperatingConditionError = i18n.translate(
          'validations.shared.mustBeSelected'
        );

      if (
        ['poor', 'inoperative'].includes(this.productOperatingCondition) &&
        this.productOperatingConditionObs === ''
      )
        this.productOperatingConditionObsError = i18n.translate(
          'validations.inspection.productConditionRequiresObservations'
        );
    },
    submitAction() {
      this.validate();
      if (this.hasErrors) return;
      if (this.$refs.camera.anyFiles()) {
        this.saveInspection();
      } else {
        this.showNoPhotosConfirmationDialog = true;
        return;
      }
    },
    saveInspection() {
      store.commit('activateFullscreenOverlay');

      const inspection = {
        productId: this.productId,
        workOrder: this.workOrder,
        operatingCondition: this.productOperatingCondition,
        observations: this.productOperatingConditionObs,
      };
      inspectionDao.saveInspection(inspection, (inspectionId) => {
        if (this.$refs.camera.anyFiles()) {
          const metadata = {
            ownerDocId: inspectionId,
          };
          attachmentDao.saveAttachments(
            metadata,
            this.$refs.camera.getFileBlobs(),
            this.afterSaveInspection
          );
        } else {
          this.afterSaveInspection();
        }
      });
    },
    afterSaveInspection() {
      store.commit('deactivateFullscreenOverlay');
      this.toast.success(i18n.translate('actions.saved'));
      this.$router.push({
        name: 'ShowProduct',
        params: { productId: this.productId },
      });
    },
  },
  computed: {
    hasErrors() {
      return (
        [
          this.productOperatingConditionError,
          this.productOperatingConditionObsError,
        ].filter((el) => el !== '').length > 0
      );
    },
  },
};
</script>
