<template>
  <div>
    <p v-show="mode === 'show'">
      <label
        @click.stop.prevent="editMode"
        :class="isEditable ? 'arbor-editable' : ''"
        >Observaçōes:</label
      >
      {{ appViewHelper.format(observations) }}
    </p>
    <div v-show="mode != 'show'">
      <label class="form-label">Observaçōes:</label>
      <textarea
        class="form-control"
        rows="5"
        v-model="newObservations"
      ></textarea>
      <button
        @click.stop.prevent="update"
        :disabled="mode === 'loading'"
        class="mt-3 me-2 btn btn-success"
      >
        <span
          v-show="mode === 'loading'"
          class="spinner-border spinner-border-sm me-2"
        ></span>
        Salvar
      </button>
      <button @click.stop.prevent="discardChanges" class="mt-3 btn btn-danger">
        Descartar alteraçōes
      </button>
      <p class="mt-2 text-danger" v-show="mode === 'error'">
        {{ i18n.translate('actions.unknownSaveError') }}
      </p>
    </div>
  </div>
  <!-- <p>{{ isEditable ? 'Edição permitida' : 'Edição não permitida' }}</p> -->
</template>

<script>
import { RemoteDb, buildRemoteDbAddrForWorkspace } from '../db/db';
import { WorkReportDao } from '../dao/work_report_dao';
import appViewHelper from '../view_helpers/app_view_helper';
import store from '../store/store';
import i18n from '../i18n/i18n';

export default {
  name: 'WorkReportWorkObservationsField',
  props: {
    work: { type: Object, required: true },
    isEditable: { type: Boolean, default: false },
  },
  setup() {
    return {
      i18n,
      appViewHelper,
    };
  },
  created() {
    this.observations = this.work.observations;

    if (this.isEditable) {
      this.newObservations = this.observations;

      const remoteWorkspaceDb = new RemoteDb(
        buildRemoteDbAddrForWorkspace(
          'workspace',
          store.state.user.currentWorkspace
        ),
        {
          username: store.state.user.username,
          password: store.state.user.password,
        }
      );
      const remoteWorkspaceAttachmentsDb = new RemoteDb(
        buildRemoteDbAddrForWorkspace(
          'workspaceAttachments',
          store.state.user.currentWorkspace
        ),
        {
          username: store.state.user.username,
          password: store.state.user.password,
        }
      );
      this.dao = new WorkReportDao(
        remoteWorkspaceDb,
        remoteWorkspaceAttachmentsDb
      );
    }
  },
  data() {
    return {
      mode: 'show',
      observations: '',
      newObservations: '',
      dao: null,
      errorMsg: '',
    };
  },
  methods: {
    showMode() {
      this.mode = 'show';
    },
    editMode() {
      if (!this.isEditable) return;

      this.mode = 'edit';
    },
    loadingMode() {
      this.mode = 'loading';
    },
    errorMode() {
      this.mode = 'error';
    },
    discardChanges() {
      this.newObservations = this.observations;
      this.showMode();
    },
    update() {
      let attrsToUpdate = { observations: this.newObservations };
      this.loadingMode();
      this.dao.updateWork(this.work._id, attrsToUpdate, (workId, result) => {
        if (result) {
          this.observations = this.newObservations;
          this.showMode();
        } else {
          this.errorMode();
        }
      });
    },
  },
};
</script>
