import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/store';
import authorization from '../auth/authorization';
import session from '../auth/session';
import { useToast } from 'vue-toastification';
import i18n from '../i18n/i18n';
import DevUtils from '../views/DevUtils';
import RemoteTechnicalSupport from '../views/RemoteTechnicalSupport';
import Sync from '../views/Sync';
import ProductList from '../views/ProductList';
import CreateProductStepOne from '../views/CreateProductStepOne';
import CreateProductStepTwo from '../views/CreateProductStepTwo';
import WorkExecutionStepOne from '../views/WorkExecutionStepOne';
import WorkExecutionStepTwo from '../views/WorkExecutionStepTwo';
import Inspection from '../views/Inspection';
import SignIn from '../views/SignIn';
import WorkspaceList from '../views/WorkspaceList';
import ShowProduct from '../views/ShowProduct';
import ProductReplacementStepOne from '../views/ProductReplacementStepOne';
import ProductReplacementStepTwo from '../views/ProductReplacementStepTwo';
import WorkReport from '../views/WorkReport';

const toast = useToast();

const routes = [
  {
    path: '/',
    redirect: '/rootProducts',
  },
  {
    path: '/signIn',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/workspaceList',
    name: 'WorkspaceList',
    component: WorkspaceList,
  },
  {
    path: '/devUtils',
    name: 'DevUtils',
    component: DevUtils,
  },
  {
    path: '/remoteTechnicalSupport',
    name: 'RemoteTechnicalSupport',
    component: RemoteTechnicalSupport,
  },
  {
    path: '/sync',
    name: 'Sync',
    component: Sync,
    props: (route) => ({
      newVersionInstalled:
        route.query['newVersionInstalled'] === 'true' ? true : false,
      signingOut: route.query['signingOut'] === 'true' ? true : false,
    }),
  },
  {
    path: '/rootProducts',
    name: 'RootProductList',
    component: ProductList,
    props: {
      linkToChildren: true,
    },
  },
  {
    path: '/products/:productId/children',
    name: 'ChildProductList',
    component: ProductList,
    props: true,
  },
  {
    path: '/products/new',
    name: 'CreateProductStepOne',
    component: CreateProductStepOne,
  },
  {
    path: '/products_:productType(.*)/new',
    name: 'CreateProductStepTwo',
    component: CreateProductStepTwo,
    props: (route) => ({
      productType: route.params.productType,
      replacedProductId: route.query['replacedProductId'],
      productReplacementId: route.query['productReplacementId'],
      prefillInstalledAt:
        route.query['prefillInstalledAt'] === 'true' ? true : false,
    }),
  },
  {
    path: '/products/:productId',
    name: 'ShowProduct',
    component: ShowProduct,
    props: true,
  },
  {
    path: '/products/:productId/workExecution/new',
    name: 'WorkExecutionStepOne',
    component: WorkExecutionStepOne,
    props: true,
  },
  {
    path: '/workExecutions/:workExecutionId/complete',
    name: 'WorkExecutionStepTwo',
    component: WorkExecutionStepTwo,
    props: true,
  },
  {
    path: '/products/:productId/inspection/new',
    name: 'Inspection',
    component: Inspection,
    props: true,
  },
  {
    path: '/products/:productId/productReplacement/new',
    name: 'ProductReplacementStepOne',
    component: ProductReplacementStepOne,
    props: true,
  },
  {
    path: '/productReplacements/:productReplacementId/complete',
    name: 'ProductReplacementStepTwo',
    component: ProductReplacementStepTwo,
    props: true,
  },
  {
    path: '/workReports',
    name: 'WorkReport',
    component: WorkReport,
    props: (route) => ({
      token: route.query['token'],
      workspace: route.query['workspace'],
      startDate: route.query['startDate'],
      endDate: route.query['endDate'],
    }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  return session
    .loadIntoStore()
    .then(() => {
      if (!store.state.user.currentWorkspace && to.name !== 'WorkspaceList') {
        toast.warning(i18n.translate('actions.authNoWorkspaceError'));
        return '/WorkspaceList';
      }
      if (store.state.user.currentWorkspace && to.name === 'WorkspaceList')
        return '/';
      if (to.name === 'SignIn') return '/';
      if (authorization.isAuthorized(store.state.user, to.name)) return true;

      toast.warning(i18n.translate('actions.authUnauthorizedError'));
      return '/';
    })
    .catch(() => {
      const authNotRequired = ['SignIn', 'WorkReport'];
      if (authNotRequired.includes(to.name)) return true;

      // Since RootProductList is the root path, we choose not to display
      // a not signed in message if it was the page trying to be accessed
      // (otherwise, users would always see a not signed in error when
      // first accessing Arbor).
      if (to.name !== 'RootProductList')
        toast.warning(i18n.translate('actions.authNotSignedInError'));
      return { name: 'SignIn' };
    });
});

router.afterEach((to, from) => {
  const toggler = document.querySelector('.navbar-toggler[aria-expanded=true]');
  if (toggler) toggler.click();

  window.scroll({ top: 0, left: 0, behavior: 'instant' });
});

export default router;
