<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="mt-3">Ferramentas de depuração</h1>
        <a class="mt-3 btn btn-danger" href="#" @click="destroyLocalDatabase">
          Excluir DBs embarcados
        </a>
        <p class="mt-3">{{ msgArborDb }}</p>
        <p class="mt-3">{{ msgWorkspaceDb }}</p>
        <p class="mt-3">{{ msgWorkspaceAttachmentsDb }}</p>
        <p class="mt-3">{{ msgSessionDb }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { arborDb, workspaceDb, workspaceAttachmentsDb } from '../db/db';
import session from '../auth/session';

export default {
  name: 'DevUtils',
  data() {
    return {
      msgArborDb: '',
      msgWorkspaceDb: '',
      msgWorkspaceAttachmentsDb: '',
      msgSessionDb: '',
    };
  },
  methods: {
    destroyLocalDatabase() {
      arborDb
        .db()
        .destroy()
        .then(() => (this.msgArborDb = 'arborDb excluído com sucesso.'))
        .catch((err) => (this.msgArborDb = err));

      workspaceDb
        .db()
        .destroy()
        .then(() => (this.msgWorkspaceDb = 'workspaceDb excluído com sucesso.'))
        .catch((err) => (this.msgWorkspaceDb = err));

      workspaceAttachmentsDb
        .db()
        .destroy()
        .then(
          () =>
            (this.msgWorkspaceAttachmentsDb =
              'workspaceAttachmentsDb excluído com sucesso.')
        )
        .catch((err) => (this.msgWorkspaceAttachmentsDb = err));

      session
        .db()
        .destroy()
        .then(() => (this.msgSessionDb = 'sessionDb excluído com sucesso.'))
        .catch((err) => (this.msgSessionDb = err));
    },
  },
};
</script>

<style scoped></style>
