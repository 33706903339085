import i18n from '../i18n/i18n';

const workExecutionViewHelper = {
  printWorkExecuted(workExecuted) {
    return workExecuted
      .map((work) => i18n.translate(`collections.workTypes.${work}`))
      .join(', ');
  },
};

export default workExecutionViewHelper;
