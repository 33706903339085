<template>
  <div class="row justify-content-center">
    <div class="col col-md-6">
      <div class="card arbor-card mb-4">
        <div class="card-header arbor-card-header">
          <h3>{{ product.data.name }}</h3>
        </div>
        <div class="card-body">
          <h4 class="card-title">
            {{ viewHelper.productType(product.schemaId) }}
          </h4>
          <h5 class="card-subtitle mb-3">
            {{
              `${i18n.translate('models.shared.brand')}: ${product.data.brand}`
            }}
          </h5>
          <router-link
            :to="{
              name: 'ShowProduct',
              params: { productId: product._id },
            }"
            class="card-link btn btn-outline-dark"
          >
            <i class="bi-list"></i>
          </router-link>
          <template v-if="linkToChildren">
            <router-link
              :to="{
                name: 'ChildProductList',
                params: { productId: product._id },
              }"
              class="card-link btn btn-outline-dark"
            >
              <i class="bi-arrow-down"></i>
            </router-link>
          </template>
          <template v-if="!linkToChildren">
            <router-link
              :to="{
                name: 'Inspection',
                params: { productId: product._id },
              }"
              class="card-link btn btn-outline-dark"
            >
              <i class="bi-eye-fill"></i>
            </router-link>
            <router-link
              :to="{
                name: 'WorkExecutionStepOne',
                params: { productId: product._id },
              }"
              class="card-link btn btn-outline-dark"
            >
              <i class="bi-wrench"></i>
            </router-link>
            <router-link
              :to="{
                name: 'ProductReplacementStepOne',
                params: { productId: product._id },
              }"
              class="card-link btn btn-outline-dark"
            >
              <i class="bi-arrow-left-right"></i>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';
import viewHelper from '../view_helpers/app_view_helper';

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true,
    },
    linkToChildren: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return { i18n, viewHelper };
  },
  data() {
    return {};
  },
};
</script>
