<template>
  <h4>Inspeçōes concluídas</h4>
  <div
    v-if="status === 'loading'"
    class="spinner-border spinner-border-sm text-dark"
  ></div>
  <div
    v-show="status === 'done' && inspections.length > 0"
    v-for="inspection in inspections"
    :key="inspection._id"
    class="row justify-content-center"
  >
    <div class="col">
      <div class="card arbor-card mb-4">
        <div class="card-body">
          <span class="d-block">
            Concluída em:
            {{ appViewHelper.formatDateTime(inspection.updatedAt) }}
          </span>
          <span class="d-block">
            Estado de funcionamento registrado:
            {{
              i18n.translate(
                `collections.productOperatingConditions.${inspection.operatingCondition}`
              )
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div v-show="status === 'done' && inspections.length === 0">
    Ainda não há relatórios desse tipo.
  </div>
</template>

<script>
import inspectionDao from '../dao/inspection_dao';
import appViewHelper from '../view_helpers/app_view_helper';
import i18n from '../i18n/i18n';

export default {
  name: 'InspectionList',
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      appViewHelper,
      i18n,
    };
  },
  created() {
    inspectionDao.inspections(this.productId, (inspections) => {
      this.inspections = inspections;
      this.status = 'done';
    });
  },
  data() {
    return {
      status: 'loading',
      inspections: [],
    };
  },
};
</script>
