<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <template v-if="productId">
          <h1 class="my-3">
            Produtos filho
            <template v-if="parentProduct">
              ({{ parentProduct.data.name }})
            </template>
          </h1>
          <router-link
            :to="{
              name: 'RootProductList',
            }"
            class="mb-3 btn btn-outline-dark"
          >
            <i class="bi-arrow-up"></i>
            Voltar para produtos raiz
          </router-link>
        </template>
        <h1 v-else class="my-3">Produtos raiz</h1>
        <h4 v-if="!hasProducts">
          Esse produto ainda não tem produtos filho cadastrados.
        </h4>
      </div>
    </div>
    <ProductCard
      v-for="product in products"
      :key="product._id"
      :product="product"
      :linkToChildren="linkToChildren"
    />
  </div>
</template>

<script>
import ProductCard from '../components/ProductCard';
import dao from '../dao/product_dao';

export default {
  name: 'ProductList',
  components: {
    ProductCard,
  },
  props: {
    productId: String,
    linkToChildren: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.productId)
      dao.getObjById(
        this.productId,
        (parentProduct) => (this.parentProduct = parentProduct)
      );
    this.getProducts();
  },
  data() {
    return {
      parentProduct: null,
      products: [],
    };
  },
  watch: {
    productId() {
      this.getProducts();
    },
  },
  methods: {
    getProducts() {
      if (this.productId) {
        dao.childProducts(this.productId, (res) => (this.products = res));
      } else {
        dao.rootProducts((res) => (this.products = res));
      }
    },
  },
  computed: {
    hasProducts() {
      return this.products.length > 0;
    },
  },
};
</script>
