export default class ProductHelper {
  constructor(product) {
    this.product = product;
    this.schemaIdParts = product.schemaId.split('::');
  }

  type() {
    return this.schemaIdParts[this.schemaIdParts.length - 2];
  }
}
