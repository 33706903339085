<template>
  <p>
    <span class="d-block fst-italic">
      <i class="d-inline-block me-1 bi-calendar3"></i>
      {{ appViewHelper.formatDateTime(work.createdAt, 'DATE_SHORT') }}</span
    >
    <span class="fw-bold">
      <i class="d-inline-block me-1 bi-eye-fill"></i>
      Inspeção
    </span>
  </p>
  <p>OS: {{ appViewHelper.format(work.workOrder) }}</p>
  <p>
    Estado do equipamento:
    {{
      i18n.translate(
        `collections.productOperatingConditions.${work.operatingCondition}`
      )
    }}
  </p>
  <WorkReportWorkObservationsField
    class="mb-3"
    :work="work"
    :isEditable="isEditable"
  />
  <template v-if="workAttachmentCollections.length > 0">
    <WorkReportAttachmentCollection
      v-for="attachmentCollection in workAttachmentCollections"
      :key="attachmentCollection._id"
      title="Fotos"
      :attachmentCollection="attachmentCollection"
      :token="token"
      :workspace="workspace"
    />
  </template>
</template>

<script>
import appViewHelper from '../view_helpers/app_view_helper';
import i18n from '../i18n/i18n';
import WorkReportAttachmentCollection from './WorkReportAttachmentCollection';
import WorkReportWorkObservationsField from './WorkReportWorkObservationsField';

export default {
  name: 'WorkReportInspection',
  components: {
    WorkReportWorkObservationsField,
    WorkReportAttachmentCollection,
  },
  props: {
    work: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    workspace: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      appViewHelper,
      i18n,
    };
  },
  data() {
    return {};
  },
  computed: {
    workAttachmentCollections() {
      if (this.work.attachments === undefined) return [];

      return this.work.attachments;
    },
  },
};
</script>
