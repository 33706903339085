import store from '../store/store';

export function newRecordDefaultFields(obj) {
  obj.createdAt = obj.updatedAt = new Date().toISOString();
  obj.deletedAt = null;
  obj.createdBy = obj.updatedBy = store.state.user.username;
  obj.createdByFullName = obj.updatedByFullName = store.state.user.fullName;

  return obj;
}

export function existingRecordDefaultFields(obj) {
  obj.updatedAt = new Date().toISOString();
  obj.updatedBy = store.state.user.username;
  obj.updatedByFullName = store.state.user.fullName;

  return obj;
}
