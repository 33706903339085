import PouchDb from 'pouchdb';
import session from './session';
import dataTypes from '../data_types/data_types';

class Auth {
  signIn(username, password, success, error) {
    const db = new PouchDb(process.env.VUE_APP_USERS_DB, {
      skip_setup: true,
      auth: {
        username: username,
        password: password,
      },
    });

    db.get(`org.couchdb.user:${username}`)
      .then((userObj) => {
        this.createSession(username, password, userObj, success, error);
      })
      .catch((err) => {
        console.log(err);

        if (dataTypes.isObject(err) && err.error === 'unauthorized')
          error('authWrongCredentialsError');
        else if (dataTypes.isObject(err) && err.message === 'Failed to fetch')
          error('authNetworkError');
        else error('authUnknownError');
      });
  }

  createSession(username, password, userObj, success, error) {
    new PouchDb('session')
      .destroy()
      .then(() => {
        const sessionDb = new PouchDb('session');
        const currentWorkspace = userObj.metadata.arborWorkspaces[0];
        const user = {
          _id: 'session',
          username: username,
          password: password,
          fullName: userObj.metadata.fullName,
          arborWorkspaces: userObj.metadata.arborWorkspaces,
          arborRoles: userObj.metadata.arborRoles,
          currentWorkspace: null,
          currentRoles: [],
          signedInSince: new Date().toISOString(),
        };
        sessionDb
          .put(user)
          .then(success)
          .catch((err) => {
            console.log(err);

            error('authUnknownError');
          });
      })
      .catch((err) => {
        console.log(err);

        error('authUnknownError');
      });
  }
}

export default new Auth();
