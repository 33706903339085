import appViewHelper from './app_view_helper';
import i18n from '../i18n/i18n';

const productViewHelper = {
  productLabelsAndValues(product, productSchema, parentProduct) {
    let labelsAndValues = [];

    if (parentProduct)
      labelsAndValues.push([
        i18n.translate('models.shared.parentId'),
        parentProduct.data.name,
      ]);
    else
      labelsAndValues.push([
        i18n.translate('models.shared.parentId'),
        appViewHelper.format(null),
      ]);

    for (const prop in product.data) {
      const translatedLabel = i18n.translate(
        productSchema.schema.properties[prop].title
      );
      labelsAndValues.push([
        translatedLabel,
        appViewHelper.format(product.data[prop]),
      ]);
    }

    return labelsAndValues.concat(
      appViewHelper.printRecordDefaultFields(product)
    );
  },
};

export default productViewHelper;
