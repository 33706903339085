import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      fullscreenOverlayActive: false,
      user: null,
    };
  },
  mutations: {
    activateFullscreenOverlay(state) {
      state.fullscreenOverlayActive = true;
    },
    deactivateFullscreenOverlay(state) {
      state.fullscreenOverlayActive = false;
    },
    loadUser(state, user) {
      state.user = user;
    },
    deleteUser(state) {
      state.user = null;
    },
  },
});

export default store;
