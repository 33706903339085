<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Completar registro de execução de serviço</h1>
        <h3>{{ product.data.name }}</h3>
        <router-link
          v-show="!showConfirmActionDialog"
          :to="{
            name: 'ShowProduct',
            params: { productId: product._id },
          }"
          class="mt-2 btn btn-outline-dark"
        >
          <i class="bi-arrow-up"></i>
          Voltar para o produto
        </router-link>
        <div v-show="!showConfirmActionDialog" class="mt-4 alert alert-warning">
          Você está completando o registro iniciado em
          {{ appViewHelper.formatDateTime(workExecution.createdAt) }}
        </div>
        <ConfirmActionDialog
          v-show="showConfirmActionDialog"
          @dialogCompleted="handleDialogCompleted"
          :mainText="i18n.translate('views.workExecution.noPhotosWarning')"
          :confirmActionText="i18n.translate('actions.confirmSave')"
          :cancelActionText="
            i18n.translate('views.workExecution.backAddPhotos')
          "
        />
        <form
          v-show="!showConfirmActionDialog"
          class="my-4"
          @submit.prevent="submitAction"
          novalidate
        >
          <Camera ref="camera" idleText="Tirar fotos (depois do serviço)" />
          <label for="work-order-input" class="form-label"
            >Ordem de serviço</label
          >
          <input
            type="text"
            id="work-order-input"
            class="form-control"
            v-model="workOrder"
          />
          <label class="form-label mt-4">Serviços realizados</label>
          <div
            v-for="(work, index) in workTypes"
            :key="work.value"
            class="form-check form-switch arbor-form-switch"
          >
            <input
              class="form-check-input"
              :id="`work_${index}`"
              type="checkbox"
              :value="work.value"
              v-model="workExecuted"
            />
            <label class="form-check-label" :for="`work_${index}`">{{
              work.text
            }}</label>
          </div>
          <div class="text-danger">
            {{ workExecutedError }}
          </div>
          <label
            for="work-executed-observations-text-area"
            class="mt-4 form-label"
            >Observaçōes adicionais</label
          >
          <textarea
            id="work-executed-observations-text-area"
            class="form-control"
            rows="5"
            v-model="workExecutedObs"
          ></textarea>
          <div class="text-danger">
            {{ workExecutedObsError }}
          </div>
          <button class="my-4 btn btn-success">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmActionDialog from '../components/ConfirmActionDialog';
import Camera from '../components/Camera';
import arborDao from '../dao/arbor_dao';
import attachmentDao from '../dao/attachment_dao';
import workExecutionDao from '../dao/work_execution_dao';
import productDao from '../dao/product_dao';
import ProductHelper from '../helpers/product_helper';
import appViewHelper from '../view_helpers/app_view_helper';
import i18n from '../i18n/i18n';
import store from '../store/store';
import { useToast } from 'vue-toastification';

export default {
  name: 'WorkExecutionStepTwo',
  components: {
    Camera,
    ConfirmActionDialog,
  },
  props: {
    workExecutionId: String,
  },
  setup() {
    return {
      i18n,
      appViewHelper,
      toast: useToast(),
    };
  },
  created() {
    workExecutionDao.getObjById(this.workExecutionId, (workExecution) => {
      this.workExecution = workExecution;

      productDao.product(this.workExecution.productId, (product) => {
        this.product = product;
        const productHelper = new ProductHelper(this.product);
        const workTypesCollectionName = `work_types::${productHelper.type()}`;

        arborDao.arborCollectionForSelect(
          workTypesCollectionName,
          (collection) => (this.workTypes = collection),
          'work_types'
        );
      });
    });
  },
  data() {
    return {
      showConfirmActionDialog: false,
      workExecution: {
        createdAt: '',
      },
      product: {
        data: {
          name: '',
        },
      },
      workOrder: '',
      workTypes: [{ text: '', value: '' }],
      workExecuted: [],
      workExecutedError: '',
      workExecutedObs: '',
      workExecutedObsError: '',
    };
  },
  methods: {
    clearPreviousErrors() {
      this.workExecutedError = '';
      this.workExecutedObsError = '';
    },
    validate() {
      this.clearPreviousErrors();

      if (this.workExecuted.length === 0)
        this.workExecutedError = i18n.translate(
          'validations.shared.atLeastOneMustBeSelected'
        );

      if (
        this.workExecuted.includes('outro_servico') &&
        this.workExecutedObs === ''
      )
        this.workExecutedObsError = i18n.translate(
          'validations.workExecution.workExecutedRequiresObservations'
        );
    },
    submitAction() {
      this.validate();
      if (this.hasErrors) return;

      if (this.$refs.camera.anyFiles()) {
        this.updateWorkExecution();
      } else {
        this.showConfirmActionDialog = true;
        return;
      }
    },
    handleDialogCompleted(confirmed) {
      if (confirmed) this.updateWorkExecution();
      else this.showConfirmActionDialog = false;
    },
    updateWorkExecution() {
      store.commit('activateFullscreenOverlay');

      this.workExecution.workOrder = this.workOrder;
      this.workExecution.workExecuted = this.workExecuted;
      this.workExecution.observations = this.workExecutedObs;

      workExecutionDao.completeWorkExecution(
        this.workExecution,
        (workExecutionId) => {
          if (this.$refs.camera.anyFiles()) {
            const metadata = {
              ownerDocId: workExecutionId,
              labels: ['afterWorkExecution'],
            };
            attachmentDao.saveAttachments(
              metadata,
              this.$refs.camera.getFileBlobs(),
              this.afterUpdateWorkExecution
            );
          } else {
            this.afterUpdateWorkExecution();
          }
        }
      );
    },
    afterUpdateWorkExecution() {
      store.commit('deactivateFullscreenOverlay');
      this.toast.success(i18n.translate('actions.saved'));
      this.$router.push({
        name: 'ShowProduct',
        params: { productId: this.product._id },
      });
    },
  },
  computed: {
    hasErrors() {
      return (
        [this.workExecutedError, this.workExecutedObsError].filter(
          (el) => el !== ''
        ).length > 0
      );
    },
  },
};
</script>
