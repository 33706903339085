import PouchDb from 'pouchdb';
import PouchDbFind from 'pouchdb-find';
import session from '../auth/session';
import store from '../store/store';

PouchDb.plugin(PouchDbFind);

export function buildRemoteDbAddr(ctx, done) {
  switch (ctx) {
    case 'arbor':
      done(`${process.env.VUE_APP_COUCH_DB_BASE_URL}/arbor`);
      return;
    case 'workspace':
      session
        .currentUser()
        .then((user) =>
          done(
            `${process.env.VUE_APP_COUCH_DB_BASE_URL}/${user.currentWorkspace}`
          )
        );
      return;
    case 'workspaceAttachments':
      session
        .currentUser()
        .then((user) =>
          done(
            `${process.env.VUE_APP_COUCH_DB_BASE_URL}/${user.currentWorkspace}_attachments`
          )
        );
      return;
    default:
      throw new (function (ctx) {
        this.message = `Received ${ctx}. Valid contexts are: [arbor, workspace, workspaceAttachments].`;
        this.name = 'RemoteDbAddrException';
      })(ctx);
  }
}

export function buildRemoteDbAddrForWorkspace(ctx, workspace) {
  switch (ctx) {
    case 'workspace':
      return `${process.env.VUE_APP_COUCH_DB_BASE_URL}/${workspace}`;
    case 'workspaceAttachments':
      return `${process.env.VUE_APP_COUCH_DB_BASE_URL}/${workspace}_attachments`;
    default:
      throw new (function (ctx) {
        this.message = `Received ${ctx}. Valid contexts are: [workspace, workspaceAttachments].`;
        this.name = 'RemoteDbAddrException';
      })(ctx);
  }
}

export async function buildRemoteDebugDbAddr(ctx, done = null) {
  let addr = '';
  let user = null;

  switch (ctx) {
    case 'arbor':
      addr = `${process.env.VUE_APP_COUCH_DB_BASE_URL}/arbor_debug`;
      break;
    case 'workspace':
      user = await session.currentUser();
      addr = `${process.env.VUE_APP_COUCH_DB_BASE_URL}/${user.currentWorkspace}_debug`;
      break;
    case 'workspaceAttachments':
      user = await session.currentUser();
      addr = `${process.env.VUE_APP_COUCH_DB_BASE_URL}/${user.currentWorkspace}_attachments_debug`;
      break;
    default:
      throw new (function (ctx) {
        this.message = `Received ${ctx}. Valid contexts are: [arbor, workspace, workspaceAttachments].`;
        this.name = 'RemoteDbAddrException';
      })(ctx);
  }

  if (done) done(addr);
  return addr;
}

export class RemoteDb {
  constructor(remoteDbAddr, opts = {}) {
    this.remoteDbAddr = remoteDbAddr;

    if (opts['username'] && opts['password']) {
      this.username = opts['username'];
      this.password = opts['password'];
    } else {
      this.username = store.state.user.username;
      this.password = store.state.user.password;
    }
  }

  db() {
    return new PouchDb(this.remoteDbAddr, {
      auth: {
        username: this.username,
        password: this.password,
      },
    });
  }
}

class LocalDb {
  constructor(localName) {
    this.name = localName;
  }

  db() {
    return new PouchDb(this.name);
  }

  startPull(remoteDb, complete, error) {
    PouchDb.replicate(remoteDb.db(), this.db())
      .on('complete', complete)
      .on('denied', error)
      .on('error', error);
  }

  startPush(remoteDb, complete, error) {
    PouchDb.replicate(this.db(), remoteDb.db())
      .on('complete', complete)
      .on('denied', error)
      .on('error', error);
  }
}

export const arborDb = new LocalDb('arbor');
export const workspaceDb = new LocalDb('workspace');
export const workspaceAttachmentsDb = new LocalDb('workspace_attachments');
