import { newRecordDefaultFields, existingRecordDefaultFields } from './utils';
import dataTypes from '../data_types/data_types';

export default class Dao {
  async getObjById(id, done) {
    try {
      const obj = await this.db().get(id);
      done(obj);
    } catch (err) {
      console.log(err);
    }
  }

  async updateObj(obj, done) {
    try {
      dataTypes.coerceRecursively(obj);
      existingRecordDefaultFields(obj);
      await this.db().put(obj);
      done(obj._id);
    } catch (err) {
      console.log(err);
    }
  }
}
