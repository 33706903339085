import { arborDb } from '../db/db';
import Dao from './dao';
import i18n from '../i18n/i18n';

class ArborDao extends Dao {
  db() {
    return arborDb.db();
  }

  // Different than other DAO methods, arborCollection returns a raw PouchDB promise.
  arborCollection(id) {
    return this.db().get(id);
  }

  async arborCollectionForSelect(
    collectionName,
    done,
    collectionI18nKey = null
  ) {
    const collectionId = `collection::${collectionName}`;
    const i18nKey = collectionI18nKey ? collectionI18nKey : collectionName;

    try {
      let res = await this.db().get(collectionId);
      done(
        res.values.map((val) => ({
          text: i18n.translate(['collections', i18nKey, val]),
          value: val,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  }

  async productSchema(productType, done) {
    try {
      const startKey = `schema::product_creation::${productType}::`;
      let res = await this.db().allDocs({
        include_docs: true,
        startkey: startKey,
        endkey: `${startKey}\ufff0`, // prefix search in CouchDB
      });
      done(res.rows[res.rows.length - 1].doc);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ArborDao();
