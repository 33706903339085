import { v4 as uuidv4 } from 'uuid';
import WorkspaceDao from './workspace_dao';
import { newRecordDefaultFields, existingRecordDefaultFields } from './utils';
import dataTypes from '../data_types/data_types';

class WorkExecutionDao extends WorkspaceDao {
  constructor() {
    super();
  }

  async workExecutions(productId, workExecutionStatus, done) {
    try {
      let res = await this.db().find({
        selector: {
          productId: productId,
        },
        use_index: 'idx_product_id',
      });

      done(
        res.docs.filter(
          (doc) =>
            doc._id.startsWith('work_execution::') &&
            doc.status === workExecutionStatus
        )
      );
    } catch (err) {
      console.log(err);
    }
  }

  async startWorkExecution(workExecution, done) {
    try {
      const id = `work_execution::${uuidv4()}`;
      Object.assign(workExecution, {
        _id: id,
        workExecuted: null,
        workOrder: null,
        observations: null,
        status: 'started',
      });
      dataTypes.coerceRecursively(workExecution);
      newRecordDefaultFields(workExecution);
      await this.db().put(workExecution);
      done(id);
    } catch (err) {
      console.log(err);
    }
  }

  async completeWorkExecution(workExecution, done) {
    try {
      Object.assign(workExecution, {
        status: 'completed',
      });
      dataTypes.coerceRecursively(workExecution);
      existingRecordDefaultFields(workExecution);
      await this.db().put(workExecution);
      done(workExecution._id);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new WorkExecutionDao();
